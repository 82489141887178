import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 100000,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#000000dd',
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '60%',
    maxHeight: '80%',
    padding: theme.spacing(4),
  },
  icon: {
    position: 'fixed',
    cursor: 'pointer',
    color: '#ffffff80',
    transition: '0.15s',
    fontSize: '60px',
    "&:hover": {
      color: 'white',
    }
  },
  close: {
    top: theme.spacing(4),
    right: theme.spacing(4),
  },
  previous: {
    top: '45%',
    left: theme.spacing(8),
  },
  next: {
    top: '45%',
    right: theme.spacing(8),
  },
  noneFound: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(4),
    color: '#fff',
  },
}));

////////// COMPONENT //////////
export default function Carousel(props) {
  const classes = useStyles();
  const { images, setCarousel } = props;
  const [image, setImage] = useState(0);

  const handlePrevious = () => {
    if (image - 1 < 0) setImage(images.length - 1);
    else setImage(image - 1);
  }
  const handleNext = () => {
    if (image + 1 > images.length - 1) setImage(0);
    else setImage(image + 1);
  }
  const handleWheel = (event) => {
    if (event.deltaY >= 0) handleNext();
    else handlePrevious();
  }

  return (<>
    <div className={classes.root} onWheel={(event) => handleWheel(event)}>
      <Icon className={classes.icon + " " + classes.close} onClick={() => setCarousel(false)}>cancel</Icon>
      {images.length > 0 ? <>
        <Icon className={classes.icon + " " + classes.previous} onClick={() => handlePrevious()}>chevron_left</Icon>
        <Icon className={classes.icon + " " + classes.next} onClick={() => handleNext()}>chevron_right</Icon>
        <img id="carouselImg" className={classes.image} src={images[image]} alt="" />
      </> : <>
          <Typography variant="h5" className={classes.noneFound}>NO IMAGES FOUND</Typography>
        </>}
    </div>
  </>)
}