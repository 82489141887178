import React, { useState, useContext, useEffect } from "react";
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from "../../global-context";

import { makeStyles, Container, SwipeableDrawer, Chip, Typography, Icon, IconButton, Divider, Tooltip, Button, Checkbox } from "@material-ui/core";
import classNames from "classnames";

import gql from "graphql-tag";
import { Query } from "react-apollo";
import * as Sentry from "@sentry/react";

import Loading from '../utils/Loading';

import DisputeModal from "./ARInvoices/DisputeModal";
import PayNowModal from "./ARInvoices/PayNowModal";
import ARIndex from "./ARInvoices/ARIndex";
import ARIndexFilter from "./ARInvoices/ARIndexFilter";
import ARInvoiceHeader from "./ARInvoices/ARInvoiceHeader";
import ARMoveDetail from "./ARInvoices/ARMoveDetail";
import ARInvoiceFooter from "./ARInvoices/ARInvoiceFooter";

import { AccordianTable, AccordianRow, TableSort } from "../reusable/AccordianTable";

import { ExportToCsv } from 'export-to-csv';
import DocumentDefinition from '../utils/PDFDocumentDefinition';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import helpers from "../utils/helpers";
import InvoicePDFModal from "./ARInvoices/InvoicePDFModal";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
};

////////// TOOLS //////////
const defaultStart = moment.utc(moment().subtract(6, `months`)).format();
const defaultEnd = moment.utc(moment()).format();

const defaultOrder = `desc`;
const defaultOrderBy = `MOVE_ID`;

const checkNeg = (num) => {
  if (num > 0) return num;
  else return 0;
}

const round = (num, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  const output = Math.round(num * multiplier) / multiplier;
  return output;
}

////////// COMPONENT //////////
function ARInvoices(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const localInvoiceId = parseInt(localStorage.getItem('invoiceId'));
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(localInvoiceId || null);
  const customerId = parseInt(ctx.customerOverride || (ctx.userProfile["https://hasura.io/jwt/claims"] ? ctx.userProfile["https://hasura.io/jwt/claims"]['x-hasura-customer-id'] : 0));
  const localExpandedRowId = parseInt(localStorage.getItem('expandedRowId'));

  const [start, setStart] = useState(Date.parse(localStorage.getItem('ar-invoice-start')) ? localStorage.getItem('ar-invoice-start') : defaultStart);
  const [end, setEnd] = useState(Date.parse(localStorage.getItem('ar-invoice-end')) ? localStorage.getItem('ar-invoice-end') : defaultEnd);

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(localExpandedRowId || 0);

  const [payNowModal, setPayNowModal] = useState({ open: false });
  const [disputeModal, setDisputeModal] = useState({ open: false });
  const [invoicePDFModal, setInvoicePDFModal] = useState({ open: false });

  const [checkedItems, setCheckedItems] = useState([])

  const handleDateChange = (value, name) => {
    const newDate = moment.utc(moment(value)).format();
    if (name === `start`) {
      localStorage.setItem(`ar-invoice-start`, newDate);
      setStart(newDate);
    }
    else {
      localStorage.setItem(`ar-invoice-end`, newDate);
      setEnd(newDate);
    }
  }

  // Set invoiceId and customerId in local storage
  useEffect(() => {
    localStorage.setItem("customerId", customerId)
  }, [customerId])
  useEffect(() => {
    localStorage.setItem("invoiceId", selectedInvoiceId)
  }, [selectedInvoiceId])
  useEffect(() => {
    localStorage.setItem("expandedRowId", expandedRowId)
  }, [expandedRowId])

  const [drawer, setDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setDrawer(true);
  }
  const handleDrawerClose = () => {
    setDrawer(false);
  }

  const applyFilters = (data) => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(armove =>
        (armove.move.id && (armove.move.id + ``).toLocaleLowerCase().includes(search)) ||
        (armove.move.reference_num && armove.move.reference_num.toLocaleLowerCase().includes(search)) ||
        (armove.move.vehicle_stock && armove.move.vehicle_stock.toLocaleLowerCase().includes(search)) ||
        (armove.move.lane.description && armove.move.lane.description.toLocaleLowerCase().includes(search))
      )
    }
  }

  const handlePayNowModalOpen = (initalFormState = `pay-now`, input = null) => {
    setPayNowModal({ ...payNowModal, open: true, input: input, initalFormState: initalFormState });
  }
  const handlePayNowModalClose = () => {
    setPayNowModal({ ...payNowModal, open: false });
  }
  const handleInvoicePDFModal = () => {
    setInvoicePDFModal({ ...invoicePDFModal, open: false });
  }

  const generateCSV = (invoice) => {
    // Create rows and options for CSV
    const createCsvRow = (armove) => {
      return {
        MOVE_ID: armove.move.id,
        REF_NUM: armove.move.reference_num ? armove.move.reference_num : `-`,
        VIN: armove.move.vehicle_vin ? armove.move.vehicle_vin : `-`,
        MOVE_DATE: moment.utc(moment(armove.billable_datetime)).format(`MM/DD/YYYY`),
        STOCK: armove.move.vehicle_stock ? armove.move.vehicle_stock.toUpperCase() : `-`,
        LANE: armove.move.lane && armove.move.lane.description ? armove.move.lane.description : `Unknown Lane`,
        DISTANCE: armove.move.lane && armove.move.lane.distance_miles ? `${armove.move.lane.distance_miles} mi` : `-`,
        TOTAL: armove.due_amount ? `$${checkNeg(armove.due_amount - armove.discount_amount).toFixed(2)}` : (armove.due_amount === 0 ? `$0.00` : `-`),
      }
    }
    const csvRows = invoice.armoves.map(armove => createCsvRow(armove));
    const csvOptions = {
      filename: `${invoice.customer.name.replace(/ /g, "_")}_Invoice_${moment(invoice.start_datetime).add(1, "day").format('MM-DD-YYYY')}_to_${moment(invoice.end_datetime).format('MM-DD-YYYY')}`,
      showTitle: true,
      title: `Invoice #${invoice.id}`,
      useKeysAsHeaders: true,
    }

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  }

  async function generatePDF(invoice, amount) {
    var docDefinition = new DocumentDefinition(invoice, amount);
    let dd = docDefinition.create(invoice, amount)
    let name = 'invoice.pdf';
    try {
      name = `${invoice.customer.name.replace(/ /g, "_")}_Invoice_${moment(invoice.start_datetime).add(1, "day").format('MM-DD-YYYY')}_to_${moment(invoice.end_datetime).format('MM-DD-YYYY')}.pdf`
    } catch (err) {
      console.log("Could not parse invoice document name")
    }
    pdfMake.createPdf(dd).download(name);
  }

  const generateCSVFromSelection = (invoice) => {
    // Create rows and options for CSV
    const createCsvRow = (armove) => {
      return {
        MOVE_ID: armove.move.id,
        REF_NUM: armove.move.reference_num ? armove.move.reference_num : `-`,
        VIN: armove.move.vehicle_vin ? armove.move.vehicle_vin : `-`,
        MOVE_DATE: moment.utc(moment(armove.billable_datetime)).format(`MM/DD/YYYY`),
        STOCK: armove.move.vehicle_stock ? armove.move.vehicle_stock.toUpperCase() : `-`,
        LANE: armove.move.lane && armove.move.lane.description ? armove.move.lane.description : `Unknown Lane`,
        DISTANCE: armove.move.lane && armove.move.lane.distance_miles ? `${armove.move.lane.distance_miles} mi` : `-`,
        TOTAL: armove.due_amount ? `$${checkNeg(armove.due_amount - armove.discount_amount).toFixed(2)}` : (armove.due_amount === 0 ? `$0.00` : `-`),
      }
    }
    const csvRows = invoice.armoves.filter(o => checkedItems.indexOf(o.id) >= 0).map(armove => createCsvRow(armove));
    // return
    const csvOptions = {
      filename: `${invoice.customer.name.replace(/ /g, "_")}_Invoice_${moment(invoice.start_datetime).add(1, "day").format('MM-DD-YYYY')}_to_${moment(invoice.end_datetime).format('MM-DD-YYYY')}`,
      showTitle: true,
      title: `Invoice #${invoice.id}`,
      useKeysAsHeaders: true,
    }

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  }

  async function generatePDFFromSelection(invoice) {
    try {
      let invoiceSubselection = [...invoice.armoves].filter(armove => checkedItems.indexOf(armove.id) >= 0)
      if (invoiceSubselection && invoiceSubselection.length < 6) {
        for await (let move of invoiceSubselection) {
          let currentInvoice = Object.assign({}, invoice, { armoves: [move] })
          await helpers.createSingleMoveInvoice(currentInvoice)
        }
      } else if (!invoiceSubselection) {
        // No AR moves on the invoice
        return;
      } else {
        setInvoicePDFModal({
          open: true,
          data: {
            invoice: invoice,
            subselection: invoiceSubselection
          },
        })
      }
    } catch (err) {
      console.error(err);
      ctx.handleNotifications(true, 'error', "Error while generating PDF")
    }
  }

  const goToMoveDetails = (moveId) => {
    props.history.push(`/moves/${moveId}`);
  }

  const handleDisputeModalOpen = (initalFormState = `dispute-add`, input = null) => {
    setDisputeModal({ ...disputeModal, open: true, input: input, initalFormState: initalFormState });
  }
  const handleDisputeModalClose = () => {
    setDisputeModal({ ...disputeModal, open: false });
  }

  const getTableActions = (invoice, amount) => {
    return [
      { name: `pay-now`, label: `Pay\xa0Now`, data: { invoice: invoice, amount: amount }, handler: handleContextMenuClick, hide: amount.due <= 0 || invoice.status !== `closed` },
      { name: `generate-csv`, label: `Generate\xa0CSV`, data: { invoice: invoice }, handler: handleContextMenuClick },
      { name: `generate-pdf`, label: `Generate\xa0PDF`, data: { invoice: invoice, amount: amount }, handler: handleContextMenuClick },
      { name: `generate-csv-selection`, label: `Generate\xa0CSV\xa0(Selection\xa0Only)`, disabled: checkedItems.length < 1 ? true : false, data: { invoice: invoice }, handler: handleContextMenuClick },
      { name: `generate-pdf-selection`, label: `Generate\xa0PDF\xa0(Selection\xa0Only)`, disabled: checkedItems.length < 1 ? true : false, data: { invoice: invoice }, handler: handleContextMenuClick },
    ];
  }
  const getRowActions = (armove) => {
    return [
      { name: `move-details`, label: `Move Details`, id: armove.move_id, handler: handleContextMenuClick },
      { name: `dispute`, label: armove.disputed === false ? `Dispute\xa0Move` : `Cancel\xa0Dispute`, data: { armove: armove }, handler: handleContextMenuClick, hide: armove.status === `paid` },
    ];
  }

  const handleContextMenuClick = async (e, data, target) => {
    if (data.action.name === `pay-now`) {
      handlePayNowModalOpen(`pay-now`, { selectedInvoice: data.invoice, ...data.amount });
      return
    }
    if (data.action.name === `generate-csv`) {
      generateCSV(data.invoice);
      return
    }
    if (data.action.name === `generate-pdf`) {
      generatePDF(data.invoice, data.amount);
      return
    }
    if (data.action.name === `generate-csv-selection`) {
      generateCSVFromSelection(data.invoice);
      return
    }
    if (data.action.name === `generate-pdf-selection`) {
      generatePDFFromSelection(data.invoice);
      return
    }
    if (data.action.name === `move-details`) {
      goToMoveDetails(data.action.id);
      return
    }
    if (data.action.name === `dispute`) {
      handleDisputeModalOpen(data.armove.disputed === false ? `dispute-add` : `dispute-cancel`, data.armove);
      return
    }
  }

  const getRowTotal = (row) => {
    return (<>
      {(row.armove.disputed || row.armove.discount_amount > 0) ?
        <Tooltip disableFocusListener title={row.armove.disputed ? `This AR record is disputed - ${row.armove.dispute_reason}` : `This AR record is discounted for $${row.armove.discount_amount} - ${row.armove.discount_reason}`}>
          <Icon className={cls.rowIcon} fontSize="small">{row.armove.disputed ? `announcement` : `local_offer`}</Icon>
        </Tooltip> : null}
      <Typography className={cls.rowTxt} style={{ display: 'inline' }}>{row.TOTAL}</Typography>
    </>)
  }


  //Checkbox handler
  const handleCheck = (event, name) => {
    event.stopPropagation()
    const checkedIndex = checkedItems.indexOf(name);
    let newChecked = [];

    if (checkedIndex === -1) {
      newChecked = newChecked.concat(checkedItems, name);
    } else if (checkedIndex === 0) {
      newChecked = newChecked.concat(checkedItems.slice(1));
    } else if (checkedIndex === checkedItems.length - 1) {
      newChecked = newChecked.concat(checkedItems.slice(0, -1));
    } else if (checkedIndex > 0) {
      newChecked = newChecked.concat(
        checkedItems.slice(0, checkedIndex),
        checkedItems.slice(checkedIndex + 1),
      );
    }
    setCheckedItems(newChecked);
  };

  const handleCheckAll = (event, rowArr) => {
    const rowIdArr = rowArr.filter(row => row.status !== "paid").map(row => row.id)
    //uncheck all if all are checked
    if ((checkedItems.length > 0) && (checkedItems.length === rowIdArr.length)) {
      setCheckedItems([]);
    } else {
      setCheckedItems(rowIdArr)
    }
  }
  //Behavior: If clicked, will check/uncheck all unpaid armoves. If all armoves are checked will become checked. If all armoves are paid, will become disabled and indeterminate.
  const HeaderCheckbox = (selectedInvoice) => {
    const armoves = selectedInvoice.armoves
    const paidArmoves = armoves.filter(o => o.status === "paid")
    const unpaidArmoves = armoves.filter(o => o.status !== "paid")
    return <Checkbox
      onClick={(event) => { handleCheckAll(event, armoves) }}
      checked={(checkedItems.length !== 0) && unpaidArmoves.length === checkedItems.length}
      indeterminate={(paidArmoves.length !== 0) && paidArmoves.length === armoves.length}
      disabled={(paidArmoves.length !== 0) && paidArmoves.length === armoves.length}
    />
  }

  const isItemChecked = id => checkedItems.indexOf(id) !== -1
  //reset checked array when new invoice selected
  //set manualPaymentMethod
  useEffect(() => {
    setCheckedItems([])
  }, [selectedInvoiceId])

  return (<>
    <PayNowModal open={payNowModal.open} onClose={handlePayNowModalClose} payNowInput={payNowModal.input} initialFormState={payNowModal.initalFormState} />
    <DisputeModal open={disputeModal.open} onClose={handleDisputeModalClose} disputeInput={disputeModal.input} initialFormState={disputeModal.initalFormState} />
    { invoicePDFModal && invoicePDFModal.open && invoicePDFModal.data && <InvoicePDFModal open={invoicePDFModal.open} onClose={handleInvoicePDFModal} data={invoicePDFModal.data} />}

    <div className={cls.root}>
      { ctx && ctx.userIsAuthenticated() && (
        <Query
          query={GET_INVOICES(`query`)}
          variables={{ customerId: customerId, start: start, end: end }}
          onError={(error) => {console.error(error); Sentry.captureException(error)}}>
          {({ loading, data, refetch }) => {
            if (loading) return <Loading fixed />
            if (data && data.arinvoices && data.arinvoices.length > 0) {
              let invoices = data.arinvoices.filter(invoice=>!(invoice.armoves.length < 1 && invoice.status === "closed"));
              if (selectedInvoiceId === null) setSelectedInvoiceId(invoices.length > 0 ? invoices[0].id : null);

              let selectedInvoice = invoices.find(invoice => invoice.id === selectedInvoiceId || null);
              if (selectedInvoice && selectedInvoice !== null) {

                // Set a consistent amount object that holds the totals
                var amount = {};
                // Valid records to calculate base totals
                const subtotalMoves = selectedInvoice.armoves.filter(item => item.due_amount > 0);
                const discountedMoves = subtotalMoves.filter(item => item.disputed === false && item.due_amount >= item.discount_amount && item.discount_amount > 0);
                const disputedMoves = subtotalMoves.filter(item => item.disputed === true);
                const paidMoves = subtotalMoves.filter(item => item.paid_amount > 0);

                // Base totals from valid records
                amount.subtotal = round(subtotalMoves.length > 0 ? subtotalMoves.map(item => item.due_amount).reduce((total, current) => total + current) : 0, 2);
                amount.discounted = round(discountedMoves.length > 0 ? discountedMoves.map(item => item.discount_amount).reduce((total, current) => total + current) : 0, 2);
                amount.disputed = round(disputedMoves.length > 0 ? disputedMoves.map(item => item.due_amount).reduce((total, current) => total + current) : 0, 2);
                amount.paid = round(paidMoves.length > 0 ? paidMoves.map(item => item.paid_amount).reduce((total, current) => total + current) : 0, 2);

                // Calculate totals
                amount.total = checkNeg(amount.subtotal - amount.discounted - amount.disputed);
                amount.dueSans = checkNeg(amount.total - amount.paid);
                amount.processingFee = round(checkNeg(amount.dueSans) * 0.03, 2);
                amount.due = checkNeg(amount.dueSans + amount.processingFee);

                // Boolean to determine if the invoice can be payed for
                var payable = amount.due > 0 && selectedInvoice.status === `closed`;

                const filteredData = applyFilters(selectedInvoice.armoves)
                var headers = [
                  { id: `HEADER-CHECKBOX`, alignLeft: true, label: `Checkbox`, value: HeaderCheckbox(selectedInvoice) },
                  { id: `MOVE_ID`, alignLeft: true, numeric: true, label: `Move\xa0ID` },
                  { id: `REF_NUM`, alignLeft: true, numeric: true, label: `Ref\xa0#` },
                  { id: `MOVE_DATE`, alignLeft: true, numeric: true, label: `Move\xa0Date` },
                  { id: `STOCK`, alignLeft: false, numeric: true, label: `Stock` },
                  { id: `LANE`, alignLeft: true, numeric: false, label: `Lane` },
                  { id: `DISTANCE`, alignLeft: false, numeric: true, label: `Distance` },
                  { id: `STATUS`, alignLeft: false, numeric: false, label: `Status` },
                  { id: `TOTAL`, alignLeft: false, numeric: true, label: `Total` },
                ]
                var rows = filteredData.map(armove => {
                  return {
                    HEADER_CHECKBOX: <Checkbox onClick={(event) => handleCheck(event, armove.id)} checked={isItemChecked(armove.id)} disabled={armove.status === "paid"} indeterminate={armove.status === "paid"} />,
                    MOVE_ID: armove.move.id,
                    REF_NUM: armove.move.reference_num ? armove.move.reference_num : `-`,
                    MOVE_DATE: moment.utc(moment(armove.billable_datetime)).format(`MM/DD/YYYY`),
                    STOCK: armove.move.vehicle_stock ? armove.move.vehicle_stock.toUpperCase() : `-`,
                    LANE: armove.move.lane && armove.move.lane.description ? armove.move.lane.description : `Unknown Lane`,
                    DISTANCE: armove.move.lane && armove.move.lane.distance_miles ? `${armove.move.lane.distance_miles} mi` : `-`,
                    STATUS: <Chip label={armove.status} variant={armove.status === 'paid' ? 'default' : 'outlined'} icon={<Icon fontSize='small'>{armove.status === 'paid' ? 'done' : 'monetization_on'}</Icon>} size="small" color={'secondary'} />,
                    TOTAL: armove.due_amount ? `$${checkNeg(armove.due_amount - armove.discount_amount).toFixed(2)}` : (armove.due_amount === 0 ? `$0.00` : `-`),
                    armove: armove
                  }
                })
              }

              return (<>
                <div className={cls.revealIndexBtn} onClick={handleDrawerOpen}><Icon>vertical_split</Icon></div>
                <div className={cls.rootTable}>
                  { selectedInvoice && selectedInvoice !== null ?
                    <Container maxWidth="lg">
                      <ARInvoiceHeader selectedInvoice={selectedInvoice} />
                      <AccordianTable
                        title={`Invoice #${selectedInvoice.id}`}
                        headers={headers}
                        rows={rows}
                        actions={getTableActions(selectedInvoice, amount)}
                        search={search}
                        defaultOrder={defaultOrder}
                        defaultOrderBy={defaultOrderBy}
                        order={order}
                        orderBy={orderBy}
                        tablePage={tablePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        setSearch={setSearch}
                        setOrder={setOrder}
                        setOrderBy={setOrderBy}
                        setTablePage={setTablePage}
                        setRowsPerPage={setRowsPerPage}
                        setExpandedRowId={setExpandedRowId}
                        button={<Button className={payable ? cls.action : cls.actionDisabled} variant="outlined" size="small" color="primary" disabled={!payable} onClick={() => handlePayNowModalOpen(`pay-now`, { selectedInvoice: selectedInvoice, ...amount })}>{`Pay\xa0Now`}</Button>}
                      >
                        {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                          .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                          .map(row => (
                            <AccordianRow
                              key={`invoice-row-${row.armove.id}`}
                              rowId={row.armove.id}
                              expandedRowId={expandedRowId}
                              setExpandedRowId={setExpandedRowId}
                              columns={[
                                { align: 'left', value: row.HEADER_CHECKBOX },
                                { align: 'left', value: row.MOVE_ID },
                                { align: 'left', value: row.REF_NUM },
                                { align: 'left', value: row.MOVE_DATE },
                                { align: 'right', value: row.STOCK },
                                { align: 'left', value: row.LANE },
                                { align: 'right', value: row.DISTANCE },
                                { align: 'right', value: row.STATUS },
                                { align: 'right', value: getRowTotal(row) },
                              ]}
                              actions={getRowActions(row.armove)}
                              className={expandedRowId === row.armove.id ? (row.armove.disputed ? cls.rowDisputedActive : cls.rowActive) : (row.armove.disputed ? cls.rowDisputed : cls.row)}
                            >
                              <ARMoveDetail key={`armovedetail-${row.armove.id}`} row={row} armove={row.armove} actions={getRowActions(row.armove)} />
                            </AccordianRow>
                          ))}
                      </AccordianTable>
                      <ARInvoiceFooter selectedInvoice={selectedInvoice} amount={amount} />
                    </Container> :
                    <Container maxWidth="sm">
                      <div className={cls.notFound}>
                        <Typography className={cls.notFoundTxt}>NO INVOICE SELECTED</Typography>
                      </div>
                    </Container>}
                </div>
                <div className={cls.spaceIndex} />
                <div className={cls.rootIndex}>
                  <div className={cls.index}>
                    <div style={{ width: '100%', height: '64px' }} />
                    <ARIndexFilter
                      start={start}
                      end={end}
                      onChange={handleDateChange}
                      refetch={refetch} />
                    <ARIndex
                      invoices={invoices}
                      selectedInvoiceId={selectedInvoiceId}
                      setSelectedInvoiceId={setSelectedInvoiceId}
                      getTableActions={getTableActions}
                      setTablePage={setTablePage} />
                  </div>
                </div>
                <SwipeableDrawer anchor="right" open={drawer} onClose={handleDrawerClose} onOpen={handleDrawerOpen} classes={{ paper: classNames(cls.drawer) }}>
                  <div>
                    <IconButton className={cls.drawerIcon} onClick={handleDrawerClose}>
                      <Icon>chevron_right</Icon>
                    </IconButton>
                  </div>
                  <Divider />
                  <ARIndexFilter
                    start={start}
                    end={end}
                    onChange={handleDateChange}
                    refetch={refetch} />
                  <ARIndex
                    invoices={invoices}
                    selectedInvoiceId={selectedInvoiceId}
                    setSelectedInvoiceId={setSelectedInvoiceId}
                    getTableActions={getTableActions}
                    setTablePage={setTablePage}
                    handleDrawerClose={handleDrawerClose} />
                </SwipeableDrawer>
              </>)
            }
            else {
              return (
                <div className={cls.rootTable}>
                  <Container maxWidth="sm">
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>NO INVOICES FOUND</Typography>
                    </div>
                  </Container>
                </div>
              )
            }
          }}
        </Query>)
      }
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(2),
    },
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  spaceIndex: {
    verticalAlign: 'top',
    position: 'relative',
    minWidth: '320px',
    width: '320px',
    height: '1px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  rootIndex: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '320px',
    height: '100%',
    borderLeft: `1px solid ${theme.palette.border}`,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  index: {
    overflow: 'auto',
    width: '320px',
    height: '100%',
    background: theme.palette.background.main,
  },
  revealIndexBtn: {
    zIndex: 1001,
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    width: '42px',
    height: '42px',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '50px',
    background: '#fff',
    color: '#808080',
    fontSize: '16px',
    "&:hover": {
      border: '1px solid #808080',
      background: '#808080',
      color: '#fff',
    },
    transition: '0.2s',
    cursor: 'pointer',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(2),
      right: '50%',
      transform: 'translateX(50%)',
    },
  },
  drawer: {
    minWidth: '240px',
    maxWidth: '320px',
  },
  drawerIcon: {
    margin: theme.spacing(1),
  },
  notFound: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#fff',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.text.primary,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowActive: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: theme.palette.primary.main,
    color: '#fff',
    boxShadow: 'none',
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowDisputed: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#fff',
    color: theme.palette.text.disabled,
    boxShadow: 'none',
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.text.disabled,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowDisputedActive: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: theme.palette.action.active,
    color: '#fff',
    boxShadow: 'none',
    "&:hover": {
      backgroundColor: theme.palette.action.active,
      color: '#fff',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowTxt: {
    color: 'inherit',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  rowIcon: {
    display: 'inline',
    verticalAlign: '-25%',
    marginRight: theme.spacing(0.5),
    color: '#inherit',
    cursor: 'pointer',
  },
  action: {
    zIndex: 1001,
    position: 'absolute',
    bottom: theme.spacing(1.5),
    left: theme.spacing(1.5),
    width: '128px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  actionDisabled: {
    zIndex: 1001,
    position: 'absolute',
    bottom: theme.spacing(1.5),
    left: theme.spacing(1.5),
    width: '128px',
    backgroundColor: theme.palette.action.disabledBackground,
    "&:hover": {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
}));

////////// GRAPHQL //////////
const GET_INVOICES = (type = 'query') => gql`
${type} get_invoices($customerId: bigint, $start: timestamptz!, $end: timestamptz!) {
  arinvoices(where: {
    customer_id: {_eq: $customerId}, 
    ${process.env.REACT_APP_ENV === `production` ? `status: {_neq: "processing"},` : ``}
    _or: [ 
      {start_datetime: {_gte: $start, _lte: $end} }, 
      {end_datetime: {_gte: $start, _lte: $end}} 
    ]}, order_by: {id: desc}) {
    id
    accounting_num
    start_datetime
    end_datetime
    status
    customer {
      id
      name
      address
      billing_frequency
      payment_terms
      auto_pay
      notify_billing
      paymentmethods(where: {type: {_eq: "manual"}}){
        id
      }
    }
    arpayments (order_by: {createdat: asc}){
      id
      amount
      status
      accounting_id
      gateway_transaction_id
      createdat
    }
    armoves(where: {active: {_eq: 1}, type: {_eq: "move"}}, order_by: {move_id: asc}) {
      id
      active
      type
      move_id
      invoice_id
      arevent_id
      billable_datetime
      discount_amount
      discount_reason
      disputed
      dispute_reason
      due_amount
      paid_amount
      status
      notes
      author
      accounting_item_id
      invoice {
        id
        accounting_num
        customer {
          id
          accounting_id
        }
      }
      move {
        id
        consumer_name
        class
        chargeable
        customer_id
        delivery_stop_id
        status
        delivery_arrived
        delivery_started
        delivery_successful
        pickup_arrived
        pickup_started
        pickup_successful
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_odometer
        vehicle_stock
        vehicle_vin
        vehicle_year
        reference_num
        lane {
          id
          description
          distance_miles
          dealer_base_discount
          dealer_stranded_discount
          tolls
          delivery {
            id
            address
            name
          }
          pickup {
            id
            address
            name
            region{
              id
              accounting_class_num
            }
          }
        }
        raterule {
          id
          rate
          type
        }
        customer {
          id
          name
          address
          billing_frequency
          payment_terms
          auto_pay
        }
      }
      details {
        id
        name
        notes
        amount
      }
      revisions(order_by: {revision: desc}) {
        id
        revision
        due_amount
        discount_amount
        discount_reason
        disputed
        dispute_reason
        details {
          id
          name
          notes
          amount
        }
      }
    }
  }
}
`

////////// EXPORT //////////
export default withRouter(ARInvoices);