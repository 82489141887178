import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import DashboardTile from './DashboardTile';
import Divide from '../../reusable/Divide';

const listOfColors = [`black`, `blue`, `brown`, `burgundy`, `gold`, `gray`, `green`, `orange`, `pink`, `purple`, `red`, `silver`, `tan`, `white`, `yellow`];

////////// COMPONENT //////////
export default function DashboardGrid(props) {
  const cls = useStyles();

  const { moves } = props;

  const getColor = (colorData) => {
    let color = colorData ? colorData.toLowerCase() : `default`;
    if (listOfColors.includes(color)) return color;
    else return `default`;
  }

  const groupBy = (objectArray, ...properties) => {
    return [...Object.values(objectArray.reduce((accumulator, object) => {
      const key = JSON.stringify(properties.map((x) => object[x] || null));
  
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(object);
      return accumulator;
    }, {}))];
  }

  return (<>
      {groupBy(moves, 'consumer_name').map(consumerMoveGroup => (
        <React.Fragment key={consumerMoveGroup[0].consumer_name}>
        {consumerMoveGroup[0].consumer_name !== null && <>
          <Divide spacer tip={`Concierge moves for ${consumerMoveGroup[0].consumer_name}`}>{consumerMoveGroup[0].consumer_name}</Divide>
          <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
            {consumerMoveGroup.map(move => (
              <Grid key={move.id} item xs className={cls.tile} key={move.id}>
                <DashboardTile move={move} color={getColor(move.vehicle_color)} />
              </Grid>
            ))}
          </Grid>
        </>}
        </React.Fragment>
      )
    )}
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  tile: {
    position: 'relative',
    minWidth: '420px',
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '360px',
      maxWidth: '420px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '320px',
      maxWidth: '360px',
    },
  },
}));