import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import GoogleStaticMapURL from '../../utils/GoogleStaticMapUrl';

////////// COMPONENT //////////
export default function LocationDetailsMap(props) {
  const cls = useStyles();

  const { location } = props;

  const staticMapUrl = (location) => GoogleStaticMapURL.build({
    key: process.env.REACT_APP_GOOGLE_STATIC_MAPS_KEY,
    markers: [
      {
        icon: 'https://s3.ap-south-1.amazonaws.com/social-auto/acknowledgement_images/fkpH1532077213498-faviconsavein32x32.png',
        shadow: true,
        lat: location.latitude,
        lon: location.longitude,
      }
    ],
    center: 'auto',
    zoom: 'auto',
    scale: false,
    size: '574x422',
    maptype: 'roadmap',
    format: 'png',
    visual_refresh: true
  });

  return (<>
    <div className={cls.main}>
      <div className={cls.paper}>

        <img className={cls.map} src={staticMapUrl(location)} alt={`location-img`} />

        <div className={cls.actions}>
          <a href={`https://maps.google.com/?q=${location.address}`} target="_blank" rel="noopener noreferrer">
            <Button className={cls.action} size="large" variant="outlined" color="secondary">Open In Maps</Button>
          </a>
        </div>

      </div>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {

  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    background: theme.palette.background.main,
  },
  map: {
    display: 'block',
    width: '100%',
    maxWidth: '574px',
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '4px',
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  action: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  actionDisabled: {
    backgroundColor: theme.palette.action.selected,
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
  },
}));