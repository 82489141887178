import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/utils/PrivateRoute";

// Standard Pages
import LoginPage from "./components/pages/Login";
import ProfilePage from "./components/pages/Profile";
import Dashboard from "./components/pages/Dashboard";
import MovePlanner from "./components/pages/MovePlanner";
import Moves from "./components/pages/Moves";
import MoveDetails from "./components/pages/MoveDetails";
import ConciergeDetails from "./components/pages/ConciergeDetails";
import Locations from "./components/pages/Locations";
import LocationDetails from "./components/pages/LocationDetails";
import Appraisals from "./components/pages/Appraisals";
import AppraisalDetails from "./components/pages/AppraisalDetails";
import Billing from "./components/pages/Billing";
import ARInvoices from "./components/pages/ARInvoices";

// Legacy Pages
// import MoveEntry from "./components/pages/MoveEntry";

// Test Pages
// import StandardModalTest from "./components/reusable/Modal/StandardModalTest";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        
        <Route path="/login" exact component={LoginPage} />
        <PrivateRoute path="/profile" exact component={ProfilePage} />

        <PrivateRoute path="/" exact component={Dashboard} />
        <PrivateRoute path="/dashboard" exact component={Dashboard} />
        <PrivateRoute path="/moves/add" exact component={MovePlanner} />
        <PrivateRoute path="/moves" exact component={Moves} />
        <PrivateRoute path="/moves/:id/" exact component={MoveDetails} />
        <PrivateRoute path="/concierge/:id/" exact component={ConciergeDetails} />
        <PrivateRoute path="/locations" exact component={Locations} />
        <PrivateRoute path="/locations/:id/" exact component={LocationDetails} />
        <PrivateRoute path="/appraisals" exact component={Appraisals} />
        <PrivateRoute path="/appraisals/:id/" exact component={AppraisalDetails} />
        <PrivateRoute path="/billing" exact component={Billing} />
        <PrivateRoute path="/invoices" exact component={ARInvoices} />

        {/* <PrivateRoute path="/legacy-moves/add" exact component={MoveEntry} /> */}

        {/* {process.env.REACT_APP_ENV !== `production` && <>
          <PrivateRoute path="/test-modal" exact component={StandardModalTest} />
        </>} */}
        
      </Switch>
    );
  }
}

export default Routes;
