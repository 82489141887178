import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../../global-context';

import { makeStyles, TextField, MenuItem, InputAdornment, Tooltip, Icon } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import * as Sentry from '@sentry/react';

import Loading from '../../utils/Loading';

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    maxWidth: '256px',
    marginRight: theme.spacing(2),
  },
  textField: {
    borderRadius: '4px',
    margin: 0,
    background: '#ffffff24',
    color: '#fff',
    '&:hover': {
      background: '#ffffff36',
    },
    transition: '0.2s',
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ffffff00',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff00',
      },
    },
  },
  input: {
    color: '#fff',
  },
  icon: {
    color: '#fff',
  },
}));

////////// COMPONENT //////////
export default function CustomerSelect(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();
  const defaultCustomerId = parseInt(
    ctx.customerOverride || ctx.userProfile[`https://hasura.io/jwt/claims`][`x-hasura-customer-id`] || ``
  );

  const [customerId, setCustomerId] = useState(defaultCustomerId);

  const handleChange = event => {
    localStorage.setItem('selectedCustomerId', event.target.value);
    ctx.setSelectedCustomerId(event.target.value);
  };
  return (
    <>
      <Query
        query={GET_CUSTOMERS}
        onError={error => {
          console.error(error);
          Sentry.captureException(error);
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) console.error('Error fetching customers:', error);
          if (data && data.customers && data.customers.length > 0) {
            if (customerId !== parseInt(ctx.customerOverride)) setCustomerId(parseInt(ctx.customerOverride));
            return (
              <>
                <div className={cls.main}>
                  <TextField
                    id='customer-select-dropdown'
                    select
                    fullWidth
                    placeholder='Select a dealer...'
                    variant='outlined'
                    margin='dense'
                    value={customerId}
                    onChange={handleChange}
                    InputProps={{
                      className: cls.input,
                      startAdornment: (
                        <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                          <Tooltip title='Dealer Select'>
                            <Icon style={{ color: '#fff', cursor: 'default' }}>group</Icon>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    SelectProps={{
                      inputProps: {
                        classes: {
                          icon: cls.icon,
                        },
                      },
                    }}
                    className={cls.textField}
                  >
                    {data.customers.map(c => (
                      <MenuItem key={`customer-select-${c.id}`} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </>
            );
          } else return `Error retrieving customers`;
        }}
      </Query>
    </>
  );
}

////////// GRAPHQL //////////
var GET_CUSTOMERS = gql`
  query get_customers {
    customers(where: { active: { _eq: 1 } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;
