import React from 'react';
import { makeStyles, Typography, Checkbox, Tooltip, IconButton, Icon } from '@material-ui/core';

const log = false;

////////// COMPONENT //////////
export default function DashboardFilter(props) {
  const cls = useStyles();

  const { moves, handleClearFilters, tags, setTags } = props;

  let filter = [];

  // log && console.log(`Filter Tags:`, tags);

  const getFilters = () => {
    if (moves && moves.length > 0) {

      let statuses = [];
      let makes = [];
      let years = [];
      let colors = [];

      statuses = [`Planning`, `Pickup`, `Delivery`, `Completed`, `Cancelled`];

      makes = moves
        .map(move => move.vehicle_make)
        .filter((make, i) => make !== null && make !== ``)
        .sort();
      makes = makes.filter((make, i) => makes.indexOf(make) === i);

      years = moves
        .map(move => move.vehicle_year)
        .filter((year, i) => year !== null && year !== ``)
        .sort();
      years = years.filter((year, i) => years.indexOf(year) === i);

      colors = moves
        .map(move => move.vehicle_color)
        .filter((color, i) => color !== null && color !== ``)
        .sort();
      colors = colors.filter((color, i) => colors.indexOf(color) === i);

      log && console.log(`Statuses`, statuses);
      log && console.log(`Makes`, makes);
      log && console.log(`Years`, years);
      log && console.log(`Colors`, colors);

      return { statuses: statuses, makes: makes, years: years, colors: colors };
    }
    else return null;
  }

  filter = getFilters();

  const handleTags = (name, str) => event => {
    if (str && str !== null) {
      let newTags = [...tags[name]];
      if (newTags.length > 0 && newTags.includes(str)) {
        let strIndex = newTags.indexOf(str);
        if (strIndex !== -1) {
          newTags.splice(strIndex, 1);
          setTags[name]([...newTags]);
        }
      }
      else {
        newTags.push(str);
        setTags[name]([...newTags]);
      }
    }
  }

  const handleConciergeView = () => {
    setTags['conciergeView'](!tags.conciergeView)
  }

  return (<>
    <div className={cls.paper}>

      <div className={cls.clear}>
        <Tooltip title="Clear Filters">
          <IconButton onClick={handleClearFilters}>
            <Icon>cached</Icon>
          </IconButton>
        </Tooltip>
      </div>

      <Typography className={cls.head}>Filtering Options</Typography>

      {filter && filter.statuses.length > 0 ? <>
        <Typography className={cls.sub}>Concierge</Typography>
        <div className={cls.option}>
          <Checkbox className={cls.optionCheck} color="primary" checked={tags.conciergeView} onChange={handleConciergeView} />
          <Typography className={cls.optionTxt}>Group By Customer</Typography>
        </div>
      </> : null}

      {filter && filter.statuses.length > 0 ? <>
        <Typography className={cls.sub}>Status</Typography>
        {filter.statuses.map((item, i) =>
          <div key={item + i} className={cls.option}>
            <Checkbox className={cls.optionCheck} color="primary" checked={tags.status.includes(item)} onChange={handleTags(`status`, item)} />
            <Typography className={cls.optionTxt}>{item}</Typography>
          </div>
        )}
      </> : null}

      {filter && filter.makes.length > 0 ? <>
        <Typography className={cls.sub}>Make</Typography>
        {filter.makes.map((item, i) =>
          <div key={item + i} className={cls.option}>
            <Checkbox className={cls.optionCheck} color="primary" checked={tags.make.includes(item)} onChange={handleTags(`make`, item)} />
            <Typography className={cls.optionTxt}>{item}</Typography>
          </div>
        )}
      </> : null}

      {filter && filter.years.length > 0 ? <>
        <Typography className={cls.sub}>Year</Typography>
        {filter.years.map((item, i) =>
          <div key={item + i} className={cls.option}>
            <Checkbox className={cls.optionCheck} color="primary" checked={tags.year.includes(item)} onChange={handleTags(`year`, item)} />
            <Typography className={cls.optionTxt}>{item}</Typography>
          </div>
        )}
      </> : null}

      {filter && filter.colors.length > 0 ? <>
        <Typography className={cls.sub}>Color</Typography>
        {filter.colors.map((item, i) =>
          <div key={item + i} className={cls.option}>
            <Checkbox className={cls.optionCheck} color="primary" checked={tags.color.includes(item)} onChange={handleTags(`color`, item)} />
            <Typography className={cls.optionTxt}>{item}</Typography>
          </div>
        )}
      </> : null}

      {!filter ? <>
        <Typography className={cls.notFound}>No filters found.</Typography>
      </> : null}
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '240px',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    background: '#fff', [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
      fontSize: '18px',
    },
  },
  clear: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  head: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
  },
  sub: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: '18px',
    fontWeight: 600,
  },
  option: {
    display: 'block',
  },
  optionCheck: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: theme.spacing(0.5),
  },
  optionTxt: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginTop: '13px',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
  },
  notFound: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '18px',
    fontWeight: 500,
  },
}));