import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { GlobalContext } from '../../../global-context';

import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';

import { makeStyles, Typography, Grid, TextField, MenuItem, Link } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/Modal';

import Loading from '../../utils/Loading';
import Success from '../../utils/Success';

const log = false;

////////// COMPONENT //////////
export default function PayNowModal(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { open, onClose, payNowInput, initialFormState } = props;

  let methods = [];

  // Controls which form should render ('pay-now', 'pay-processing', 'pay-success')
  const [formState, setFormState] = useState(`pay-now`);

  const [methodIndex, setMethodIndex] = useState(0);
  const [methodObj, setMethodObj] = useState(null);
  const [payError, setPayError] = useState(null);

  useEffect(() => {
    if (initialFormState) setFormState(initialFormState);
    log && console.log(`Selected method obj:`, methodObj);
  }, [initialFormState, methodObj]);

  const handleMethodChange = event => {
    if (event) {
      setMethodIndex(event.target.value);
      setPayError(null);
    }
  }

  const handleClose = () => {
    if (onClose) onClose();
    setMethodIndex(0);
    setMethodObj(null);
    setPayError(null);
  }

  // Call netlify function
  const handleProcessPayment = async () => {
    if (methodObj) {
      try {
        setFormState(`pay-processing`);
        axios({
          method: `POST`,
          url: `/.netlify/functions/processPaymentAR`,
          data: {
            armoves: payNowInput.selectedInvoice.armoves,
            callMethod: `dealer-pay-button`,
            paymentMethod: methodObj,
            invoiceId: payNowInput.selectedInvoice.id
          },
          headers: {
            'authorization': `Bearer ${ctx.userToken}`
          }
        }).then(res => {
          console.log('RES', res)
          if(res.data === "success"){
          log && console.log(`Process payment success:`, res.data);
          setFormState(`pay-success`);
        }else{
          log && console.log(`Process payment failure:`, res.data);
          setPayError(`Payment failed to process: ${res.data}`);
          setFormState(`pay-now`);
          }
        }).catch(err => {
          console.log(`An error occurred while processing the payment:`, err);
          setPayError(`An error occurred while processing your payment. ${err}.`);
          setFormState(`pay-now`);
        });
      }
      catch (err) {
        console.log(`An error occurred when attempting to POST payment:`, err);
        setPayError(`An error occurred when attempting to POST payment ${err}.`);
      }
    }
    else {
      setPayError(`Error finding a payment method. Please select a valid payment method to continue.`);
    }
  }

  return (<>
    <Modal open={open}>

      {formState === `pay-now` && payNowInput && <>
        <ModalHeader handleClose={handleClose}>Pay Now</ModalHeader>

        <ModalContent subtitle={`By clicking "Make Payment", your method of payment will be charged for the amount displayed as the "Current Amount Due". This amount excludes any disputed moves on the invoice. If there are any disputed moves, the invoice will be considered partially paid until an agreement\xa0is\xa0reached.`}>
          {payError !== null ? <>
            <Typography style={{ color: 'red', fontWeight: 500 }}>{payError}</Typography>
            <div className={cls.break} />
          </> : null}
          <Subscription
            subscription={GET_PAYMENT_METHODS}
            variables={{ customerId: parseInt(ctx.customerOverride || ctx.userProfile[`https://hasura.io/jwt/claims`][`x-hasura-customer-id`]) }}>
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if (error) console.log(`Error finding payment methods:`, error);
              if (data && data.arpaymentmethods && data.arpaymentmethods.length > 0) {
                methods = data.arpaymentmethods;
                setMethodObj(methods[methodIndex]);
                return (<>
                  <div className={cls.break} />
                  <TextField
                    fullWidth
                    required
                    select
                    label="Payment Method"
                    value={methodIndex}
                    onChange={handleMethodChange}
                    margin="none"
                    variant="outlined">
                    <MenuItem key={"Select Payment Method"} value={``}><em>Select Payment Method</em></MenuItem>
                    {methods ? methods.map((m, i) => (
                      <MenuItem key={m.id} value={i}>{m.name}{m.primary === true ? ` (Primary)` : null}</MenuItem>
                    )) : null}
                  </TextField>
                  <div className={cls.smallBreak} />
                  <Typography style={{ fontSize: "12px" }}>
                    <Link href="/billing">Manage Payment Methods</Link>
                  </Typography>
                  <div className={cls.bigBreak} />
                </>)
              }
            }}
          </Subscription>
          <Grid container spacing={0}>

            {(payNowInput.subtotal) && (payNowInput.discounted || payNowInput.disputed) ? <>
              <Grid item xs={6}><Typography className={cls.key}>Subtotal</Typography></Grid>
              <Grid item xs={6}><Typography className={cls.val}>${payNowInput.subtotal.toFixed(2)}</Typography></Grid>

              {payNowInput.discounted ? <>
                <Grid item xs={6}><Typography className={cls.key}>Discounted</Typography></Grid>
                <Grid item xs={6}><Typography className={cls.val}>(${payNowInput.discounted.toFixed(2)})</Typography></Grid>
              </> : null}

              {payNowInput.disputed ? <>
                <Grid item xs={6}><Typography className={cls.key}>Disputed</Typography></Grid>
                <Grid item xs={6}><Typography className={cls.val}>(${payNowInput.disputed.toFixed(2)})</Typography></Grid>
              </> : null}

              <div className={cls.lineBreak} />
            </> : null}

            {payNowInput.total || payNowInput.total === 0 ? <>
              <Grid item xs={6}><Typography className={cls.keyDemi}>Total</Typography></Grid>
              <Grid item xs={6}><Typography className={cls.valDemi}>${payNowInput.total.toFixed(2)}</Typography></Grid>
            </> : null}

            {payNowInput.paid || payNowInput.paid === 0 ? <>
              <Grid item xs={6}><Typography className={cls.keyDemi}>Amount Paid</Typography></Grid>
              <Grid item xs={6}><Typography className={cls.valDemi}>${payNowInput.paid.toFixed(2)}</Typography></Grid>
            </> : null}

            <div className={cls.lineBreak} />

            {payNowInput.dueSans ? <>
              <Grid item xs={6}><Typography className={cls.keyBold}>Amount Due</Typography></Grid>
              <Grid item xs={6}><Typography className={cls.valBold}>${payNowInput.dueSans.toFixed(2)}</Typography></Grid>
            </> : null}

            {payNowInput.processingFee ? <>
              <Grid item xs={6}><Typography className={cls.keyBold}>+ Processing Fee (3%)</Typography></Grid>
              <Grid item xs={6}><Typography className={cls.valBold}>${payNowInput.processingFee.toFixed(2)}</Typography></Grid>
            </> : null}

            <div className={cls.lineBreak} />

            {payNowInput.due || payNowInput.due === 0 ? <>
              <Grid item xs={6}><Typography className={cls.keyGrand}>Amount Due{payNowInput.due ? ` (+Processing)\xa0` : ``}</Typography></Grid>
              <Grid item xs={6}><Typography className={cls.valGrand}>${payNowInput.due.toFixed(2)}</Typography></Grid>
            </> : null}

          </Grid>
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => handleProcessPayment()} disabled={!methodObj}>Make Payment</ModalAction>
          <ModalAction onClick={() => handleClose()} color="secondary">Cancel</ModalAction>
        </ModalFooter>
      </>}

      {formState === `pay-processing` && <>
        <ModalHeader>Processing Payment...</ModalHeader>

        <ModalContent>
          <div className={cls.break} />
          <Loading relative />
          <div className={cls.break} />
        </ModalContent>
      </>}

      {formState === `pay-success` && <>
        <ModalHeader handleClose={handleClose}>Payment Approved!</ModalHeader>

        <ModalContent>
          <Success />
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => handleClose()} color="secondary">Done</ModalAction>
        </ModalFooter>
      </>}

    </Modal>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  key: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 400,
  },
  val: {
    color: theme.palette.text.secondary,
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 400,
  },
  keyDemi: {
    color: theme.palette.text.tertiary,
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 400,
  },
  valDemi: {
    color: theme.palette.text.tertiary,
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 400,
  },
  keyBold: {
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 400,
  },
  valBold: {
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 400,
  },
  keyGrand: {
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 600,
  },
  valGrand: {
    textAlign: 'right',
    fontSize: '20px',
    fontWeight: 600,
  },

  block: {
    display: 'block',
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(0.5),
  },
  break: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(2.5),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    margin: '8px 0',
    background: '#ddd',
  },
}));

const GET_PAYMENT_METHODS = gql`
subscription get_payment_methods($customerId: bigint!) {
  arpaymentmethods(where: {customer_id: {_eq: $customerId}, active: {_eq: true}, type: {_neq: "manual"}}, order_by: {primary: desc, name: asc}) {
    id
    customer_id
    gateway_token
    active
    primary
    account_type
    account_number
    name
    type
    card_type
    expiration
    routing_number
    source
  }
}
`;