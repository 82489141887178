import moment from 'moment';
import DocumentDefinition from './PDFDocumentDefinition';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
};

const helpers = {
  filterDuplicatesByProperty: (arr, property) => {
    const unique = arr
      .map(e => e[property])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e])
      .map(e => arr[e]);
    return unique;
  },
  findLaneById: (lanes, id) => {
    return lanes.find(o => o.id === id);
  },
  findLaneByLocationIds: (lanes, pickupId, deliveryId) => {
    return lanes.find(o => o.id && o.pickup && o.delivery && o.pickup.id === pickupId && o.delivery.id === deliveryId);
  },
  validateLocationByExistingLanes: (lanes, location, locationType) => {
    const correspondingLocations = helpers.filterLocationsByExistingLane(
      lanes,
      location,
      locationType
    );
    if (
      !correspondingLocations ||
      correspondingLocations === null ||
      correspondingLocations === undefined ||
      correspondingLocations.length < 1
    )
      return false;
    return true;
  },
  filterLocationsByExistingLane: (lanes, location, locationType) => {
    // Show locations for either pickup or delivery that correspond to an existing lane
    let correspondingLocations = [];
    const inverseLocationType = locationType === "delivery" ? "pickup" : "delivery";
    if (!location || !locationType) return correspondingLocations;
    const existingLanes = lanes.filter(
      o => o[locationType].id === location.id
    );
    console.log("lanes filtered to", existingLanes);
    for (let lane of existingLanes) {
      correspondingLocations.push(lane[inverseLocationType]);
    }
    console.log(
      "locations filtered to",
      helpers.filterDuplicatesByProperty(correspondingLocations, "id")
    );
    return helpers.filterDuplicatesByProperty(correspondingLocations, "id");
  },
  findLocationById: (locations, id) => {
    return locations.find(o => o.id === id);
  },
  findLocationByName: (locations, name) => {
    return locations.find(o => o.name === name);
  },
  findReturnRideById: (moves, id) => {
    return moves.find(o => o.id === id);
  },
  findCustomerById: (customers, id) => {
    return customers.find(o => o.id === id);
  },
  sortObjArrayByName: array => {
    return array.sort((a, b) => {
      return a.name.toUpperCase() < b.name.toUpperCase()
        ? -1
        : a.name.toUpperCase() > b.name.toUpperCase()
          ? 1
          : 0;
    });
  },
  deleteArrEleByNestedObjProp: (array, arrayOfProps, value) => {
    let objStructure = "";
    // loop through array of properties (ordered highest-level to lowest) and conditionally build object path
    for (let prop of Array.from(arrayOfProps)) {
      objStructure += `.${prop}`
    };
    let newArr = array;
    newArr.splice(newArr.findIndex(obj => obj + objStructure === value), 1);
    return newArr; // Will return a new array without the deleted element
  },
  createNewMoveURL: (obj) => {
    let paramsArray = [];
    // check for each possible query and enter its abr. name and value
    let checkProp = (abr, key) => {
      // escapes non-URL complient characters (just in case)
      var esc = encodeURIComponent
      if (obj[key] !== undefined && obj[key] !== null) {
        let param = abr + "=" + esc(obj[key])
        paramsArray.push(param)
      }
    }
    // vehicle details
    checkProp('vs', 'vehicle_stock')
    checkProp('vv', 'vehicle_vin')
    checkProp('vc', 'vehicle_color')
    checkProp('vy', 'vehicle_year')
    checkProp('vma', 'vehicle_make')
    checkProp('vmo', 'vehicle_model')
    checkProp('mf', 'manual_flag')
    // lane
    checkProp('la', 'lane_id')
    // locations
    checkProp('p', 'pickup_id')
    checkProp('p', 'destination_location_id')
    checkProp('d', 'delivery_id')
    // add query endpoint, question mark, and ampersands
    let queryURL = '/moves/add?' + (paramsArray.join('&'))
    return queryURL
  },
  createSingleMoveInvoice: async (invoice) => {
    try {
      // Set a consistent amount object that holds the totals
      var amount = {};
      // Valid records to calculate base totals
      const subtotalMoves = invoice.armoves.filter(item => item.due_amount > 0);
      const discountedMoves = subtotalMoves.filter(item => item.disputed === false && item.due_amount >= item.discount_amount && item.discount_amount > 0);
      const disputedMoves = subtotalMoves.filter(item => item.disputed === true);
      const paidMoves = subtotalMoves.filter(item => item.paid_amount > 0);
      // Base totals from valid records
      amount.subtotal = round(subtotalMoves.length > 0 ? subtotalMoves.map(item => item.due_amount).reduce((total, current) => total + current) : 0, 2);
      amount.discounted = round(discountedMoves.length > 0 ? discountedMoves.map(item => item.discount_amount).reduce((total, current) => total + current) : 0, 2);
      amount.disputed = round(disputedMoves.length > 0 ? disputedMoves.map(item => item.due_amount).reduce((total, current) => total + current) : 0, 2);
      amount.paid = round(paidMoves.length > 0 ? paidMoves.map(item => item.paid_amount).reduce((total, current) => total + current) : 0, 2);
      // Calculate totals
      amount.total = checkNeg(amount.subtotal - amount.discounted - amount.disputed);
      amount.dueSans = checkNeg(amount.total - amount.paid);
      amount.processingFee = round(checkNeg(amount.dueSans) * 0.03, 2);
      amount.due = checkNeg(amount.dueSans + amount.processingFee);
      // Build the PDF
      var docDefinition = new DocumentDefinition(invoice, amount);
      let dd = docDefinition.create(invoice, amount)
      let name = 'invoice.pdf';
      try {
        if (invoice.armoves && invoice.armoves[0] && invoice.armoves[0].move && invoice.armoves[0].move.consumer_name) {
          name = `${invoice.armoves[0].move.id}_${invoice.armoves[0].move.consumer_name.replace(' ', '_')}_${moment.utc(moment(invoice.armoves[0].billable_datetime)).format(`MM-DD-YYYY`)}_invoice.pdf`
        }
        else {
          name = `${invoice.armoves[0].move.id}_${moment.utc(moment(invoice.armoves[0].billable_datetime)).format(`MM-DD-YYYY`)}_invoice.pdf`
        }
      } catch (err) {
        console.error("Could not parse invoice document name:", err)
      }
      pdfMake.createPdf(dd).download(name);
    } catch (err) {
      console.error("Failed to generate PDF of invoice:", err)
      throw new Error(err)
    }
  }
};

const checkNeg = (num) => {
  if (num > 0) return num;
  else return 0;
}

const round = (num, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  const output = Math.round(num * multiplier) / multiplier;
  return output;
}

export default helpers;
