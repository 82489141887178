import React from 'react';
import { makeStyles, DialogActions } from '@material-ui/core';

////////// COMPONENT //////////
export default function ModalFooter(props) {
  const cls = useStyles();
  const { className } = props;
  return <DialogActions className={cls.padding + ' ' + className}>{props.children}</DialogActions>;
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  padding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: 0,
    background: theme.palette.background.dark,
  },
}));
