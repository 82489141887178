import React from 'react';
import ReactToPrint from 'react-to-print';
import moment from 'moment';

import { makeStyles, Typography, Tooltip, IconButton, Icon, Grid, Button } from '@material-ui/core';

import bb_logo from '../../../static/bb_primary.svg';

const capFirst = str => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
};
const moneyWithCommas = val => {
  if (val) {
    const valString = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `$` + valString;
  } else return `$0`;
};
const cleansePhoneNumber = num => {
  if (num) {
    var cleaned = ('' + num).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
};

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
export default function AppraisalPrint(props) {
  const cls = useStyles();

  const componentRef = React.useRef();

  const { appraisal, mileage, image, action, brand } = props;

  const move = appraisal.moves[0] || {};
  const customer = appraisal.customer || {};

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref} className={cls.base}>
      <div
        className={cls.header}
        style={{
          background: brand && brand.secondary_color ? brand.secondary_color : `#323232`,
          color: brand && brand.primary_color ? brand.primary_color : `#ffffff`,
        }}
      >
        <Grid container spacing={0} justify='space-between' alignItems='center'>
          <Grid item xs>
            <Typography className={cls.headerText}>APPRAISAL OFFER</Typography>
          </Grid>
          {brand && brand.logo_url ? (
            <Grid item>
              <img className={cls.logo} src={brand.logo_url} width='180' alt='dealership-logo' />
            </Grid>
          ) : null}
        </Grid>
      </div>
      <div className={cls.body}>
        <Grid container spacing={2} alignContent='center'>
          {image && image.original ? (
            <Grid item>
              <img src={image.original} width='280' alt='vehicle' />
            </Grid>
          ) : null}
          <Grid item xs>
            <div className={cls.vehicleYear}>
              <Typography className={cls.vehicleYearText}>{move.vehicle_year}</Typography>
            </div>
            <div className={cls.vehicleMain}>
              <Typography className={cls.vehicleMakeText}>{move.vehicle_make}</Typography>
              <Typography className={cls.vehicleModelText}>{move.vehicle_model}</Typography>
            </div>

            <div className={cls.mdBreak} />

            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography className={cls.vehicleKeyText}>NAME</Typography>
                <Typography className={cls.vehicleValText}>{move.consumer_name || `-`}</Typography>

                <div className={cls.smBreak} />

                <Typography className={cls.vehicleKeyText}>CONTACT</Typography>
                <Typography className={cls.vehicleValText}>{cleansePhoneNumber(move.consumer_phone) || `-`}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className={cls.vehicleKeyText}>FULL VIN</Typography>
                <Typography className={cls.vehicleValText}>{appraisal.vin || `-`}</Typography>

                <div className={cls.smBreak} />

                <Typography className={cls.vehicleKeyText}>MILEAGE</Typography>
                <Typography className={cls.vehicleValText}>{mileage || 0}</Typography>

                {/* <div className={cls.smBreak} />

                <Typography className={cls.vehicleKeyText}>COLOR</Typography>
                <Typography className={cls.vehicleValText}>{move.vehicle_color || `-`}</Typography> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className={cls.mdBreak} />

        <div className={cls.lineBreak} />

        <div className={cls.mdBreak} />

        <Grid container spacing={0}>
          <Grid item xs={4}>
            <div className={cls.gridSplitL}>
              <div className={cls.block}>
                <Typography className={cls.bodyKeyText}>DEALERSHIP</Typography>
                <Typography className={cls.bodyValText}>{customer.name || `-`}</Typography>
              </div>

              <div className={cls.mdBreak} />

              <div className={cls.block}>
                <Typography className={cls.bodyKeyText}>VEHICLE CONDITION</Typography>
                <Typography className={cls.bodyValText}>{capFirst(appraisal.condition) || `-`} Condition</Typography>
              </div>

              <div className={cls.mdBreak} />

              {appraisal.trim_overrides && appraisal.trim_overrides.length > 0 && (
                <>
                  <div className={cls.block}>
                    <Typography className={cls.bodyKeyText}>ADJUSTED TRIM</Typography>
                    <div className={cls.xsBreak} />
                    {appraisal.trim_overrides.map((trim, i) => (
                      <>
                        <Typography key={`print-trim-${i}`} className={cls.bodyValText}>
                          <strong>{trim.included_in_price ? `-` : `+`}</strong> {trim.name || trim.uoc}
                        </Typography>
                        {i < appraisal.trim_overrides.length - 1 && <div className={cls.xsBreak} />}
                      </>
                    ))}
                  </div>

                  <div className={cls.mdBreak} />
                </>
              )}

              <div className={cls.block}>
                <Typography className={cls.bodyKeyText}>VEHICLE NOTES</Typography>
                <div className={cls.notesBox}>
                  <Typography className={cls.notesText}>{appraisal.vehicle_notes || ``}</Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className={cls.gridSplitR}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography className={cls.bodyKeyText}>OFFER DATE</Typography>
                  <Typography className={cls.bodyValText}>{moment().format(`MM/DD/YYYY`)}</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={cls.bodyKeyText}>APPRAISER CREDENTIALS</Typography>
                  <Typography className={cls.bodyValText}>{appraisal.appraiser_credentials || `-`}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography className={cls.bodyKeyText}>APPRAISER NAME</Typography>
                  <Typography className={cls.bodyValText}>{appraisal.appraiser_name || `-`}</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={cls.bodyKeyText}>APPRAISER CONTACT</Typography>
                  <Typography className={cls.bodyValText}>{appraisal.appraiser_contact || `-`}</Typography>
                </Grid>
              </Grid>

              <div className={cls.mdBreak} />

              <div className={cls.block}>
                <Typography className={cls.bodyKeyText}>APPRAISER MESSAGE</Typography>
                <div className={cls.notesBox}>
                  <Typography className={cls.notesText}>{appraisal.appraiser_notes || ``}</Typography>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={cls.bb}>
        <Grid container spacing={1} alignItems='center' wrap='nowrap'>
          <Grid item>
            <Typography className={cls.bbText}>POWERED BY</Typography>
          </Grid>
          <Grid item>
            <a href='https://www.blackbook.com/' target='_blank' rel='noopener noreferrer'>
              {bb_logo ? <img src={bb_logo} width='80' alt='blackbook logo' /> : null}
            </a>
          </Grid>
        </Grid>
      </div>

      <div className={cls.offer}>
        <Typography className={cls.offerText}>FINAL OFFER</Typography>
        <div className={cls.offerBox}>
          <Typography className={cls.offerBoxText}>{moneyWithCommas(appraisal.value_total)}</Typography>
        </div>
        {appraisal.appraiser_expire_time_utc ? (
          <Typography className={cls.offerSubText}>
            Offer expires {moment(appraisal.appraiser_expire_time_utc).format(`MM/DD/YYYY`)}
          </Typography>
        ) : null}
      </div>
    </div>
  ));

  return (
    <>
      {action === `icon-button` ? (
        <ReactToPrint
          trigger={() => (
            <Tooltip title='Print appraisal or save as a PDF'>
              <IconButton className={cls.printIconParent}>
                <Icon className={cls.icon}>print</Icon>
              </IconButton>
            </Tooltip>
          )}
          content={() => componentRef.current}
          documentTitle={`appraisal_${appraisal.vin}_${moment().format(`MM-DD-YYYY`)}`}
        />
      ) : null}

      {action === `button` ? (
        <ReactToPrint
          trigger={() => (
            <Tooltip title='Print appraisal or save as a PDF'>
              <Button className={cls.button} fullWidth color='primary' variant='contained'>
                Print Appraisal
              </Button>
            </Tooltip>
          )}
          content={() => componentRef.current}
          documentTitle={`appraisal_${appraisal.vin}_${moment().format(`MM-DD-YYYY`)}`}
        />
      ) : null}

      <div style={{ display: `none` }}>
        <ComponentToPrint ref={componentRef} />
      </div>
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  base: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  header: {
    position: 'relative',
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  logo: {
    minWidth: 180,
    marginTop: theme.spacing(0.75),
  },
  headerText: {
    lineHeight: 1,
    fontSize: 32,
    fontWeight: 700,
  },
  body: {
    position: 'relative',
    padding: theme.spacing(3),
  },
  printIconParent: {
    '&:hover $icon': {
      color: theme.palette.info.main,
    },
  },
  icon: {
    transition: '0.2s',
  },
  button: {
    color: '#fff',
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
    },
    transition: '0.2s',
  },
  block: {
    display: 'block',
  },
  keyText: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  valText: {
    display: 'inline-block',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  vehicleImage: {
    maxWidth: '100%',
  },
  vehicleYear: {
    verticalAlign: 'top',
    display: 'inline-block',
    border: `3px solid ${theme.palette.text.primary}`,
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: 'center',
  },
  vehicleYearText: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  vehicleMain: {
    verticalAlign: 'top',
    display: 'inline-block',
  },
  vehicleMakeText: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 300,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
  },
  vehicleModelText: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
  },
  vehicleKeyText: {
    lineHeight: 1,
    marginBottom: theme.spacing(0.25),
    fontSize: 10,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  vehicleValText: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 300,
    color: theme.palette.text.primary,
  },
  gridSplitL: {
    paddingRight: theme.spacing(2),
    borderRight: `1px solid ${theme.palette.border}`,
  },
  gridSplitR: {
    paddingLeft: theme.spacing(2),
  },
  bodyKeyText: {
    lineHeight: 1,
    marginBottom: theme.spacing(0.25),
    fontSize: 10,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  bodyValText: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 300,
    color: theme.palette.text.primary,
  },
  notesBox: {
    width: '100%',
    minHeight: 80,
    border: `1px solid ${theme.palette.text.secondary}`,
    padding: theme.spacing(1),
  },
  notesText: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  bb: {
    position: 'absolute',
    bottom: 24,
    left: 24,
  },
  bbText: {
    lineHeight: 1,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 400,
  },
  offer: {
    position: 'absolute',
    bottom: 24,
    right: 24,
  },
  offerText: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 300,
    color: theme.palette.text.primary,
  },
  offerBox: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: theme.palette.text.primary,
  },
  offerBoxText: {
    display: 'inline-block',
    lineHeight: 1,
    fontSize: 36,
    fontWeight: 700,
    color: theme.palette.text.contrast,
  },
  offerSubText: {
    marginTop: theme.spacing(0.75),
    lineHeight: 1,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.text.primary,
  },
  xsBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  smBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  mdBreak: {
    width: '100%',
    height: theme.spacing(2),
  },
  lgBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  xlBreak: {
    width: '100%',
    height: theme.spacing(4),
  },
  lineBreak: {
    width: '100%',
    height: 1,
    background: theme.palette.border,
  },
}));
