import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles, Grid, Typography, Button, Tooltip } from '@material-ui/core';
import Carousel from "../../reusable/Carousel";

////////// COMPONENT //////////
export default function ConciergeDetailsLocation(props) {
  const cls = useStyles();

  const { title, type, move, pickupImages, deliveryImages } = props;

  const [pickupCarousel, setPickupCarousel] = useState(false);
  const [deliveryCarousel, setDeliveryCarousel] = useState(false);

  // Get pickup or delivery time based on which location this is
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // FOR FUTURE REFERENCE: We need to be able to predict the delivery time after the concierge move is picked up //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const getTime = () => {
    if (type === `pickup` && move.pickup_arrived) return moment(move.pickup_arrived).format(`MM/DD/YYYY LT`);
    else if (type === `delivery` && move.delivery_arrived) return moment(move.delivery_arrived).format(`MM/DD/YYYY LT`);
    else if (move[`${type}_time`]) return moment(move[`${type}_time`]).format(`MM/DD/YYYY LT`);
    else return `N/A`;
  }

  return (<>
    <div className={cls.paper} style={{ background: type === `pickup` ? `#fff` : `#f4f4f4` }}>
      <Grid container spacing={0} alignItems="flex-start" wrap="nowrap">
        <Grid item xs>
          <Typography className={cls.headTxtL}>{title}</Typography>
        </Grid>
        <Grid item>
          <Tooltip title={`Expected arrival time at this location`} placement="top">
            <Typography className={cls.headTxtR}>{getTime()}</Typography>
          </Tooltip>
        </Grid>
      </Grid>
      <div className={cls.smallBreak} />

      <a href={`https://maps.google.com/?q=${move.lane[type].address}`} target="_blank" rel="noopener noreferrer">
        <Tooltip placement="top" title={`Click to see this location on Google Maps`}>
          <div className={cls.location}>
            <Typography className={cls.nameTxt}>{move.lane[type].name}</Typography>
            <Typography className={cls.addressTxt}>{move.lane[type].address}</Typography>
          </div>
        </Tooltip>
      </a>

      <div className={cls.break} />

      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <Button disabled={!pickupImages || !pickupImages.length > 0} variant="outlined" color="secondary" size="large" style={{ width: "100%" }} onClick={() => setPickupCarousel(true)}>{`Pickup Images`}</Button>
          {pickupCarousel && <Carousel images={pickupImages} setCarousel={setPickupCarousel} />}
        </Grid>
        <Grid item md={6} xs={12}>
          <Button disabled={!deliveryImages || !deliveryImages.length > 0} variant="outlined" color="secondary" size="large" style={{ width: "100%" }} onClick={() => setDeliveryCarousel(true)}>{`Delivery Images`}</Button>
          {deliveryCarousel && <Carousel images={deliveryImages} setCarousel={setDeliveryCarousel} />}
        </Grid>
      </Grid>
    </div >
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    cursor: 'default',
  },
  location: {
    padding: theme.spacing(1),
    borderRadius: '4px',
    backgroundColor: '#00000000',
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    cursor: 'pointer',
    transition: '0.2s',
  },
  nameTxt: {
    lineHeight: 1.25,
    fontSize: '18px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  addressTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  timeTxtKey: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  timeTxtVal: {
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  infoTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
}));