import React from 'react';

import { makeStyles, ButtonGroup, Button } from '@material-ui/core';

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  btnGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  btn: {
    minHeight: '56px',
  },
  btnActive: {
    minHeight: '56px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
}));

////////// COMPONENT //////////
export default function VehicleForm(props) {
  const cls = useStyles();

  const { sequence, onChange, switchLabelA, switchLabelB } = props;

  return (<>
    <ButtonGroup fullWidth className={cls.btnGroup} color="primary">
      <Button fullWidth size="large" className={sequence === 0 ? cls.btnActive : cls.btn} onClick={() => onChange(0)}>{switchLabelA || `Label A`}</Button>
      <Button fullWidth size="large" className={sequence === 1 ? cls.btnActive : cls.btn} onClick={() => onChange(1)}>{switchLabelB || `Label B`}</Button>
    </ButtonGroup>
  </>)
}