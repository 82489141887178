import React, { useContext } from 'react';
import { GlobalContext } from "../../../global-context";

import gql from "graphql-tag";
import * as Sentry from "@sentry/react";

import IframeComm from 'react-iframe-comm';

const log = false;

////////// COMPONENT //////////
export default function MethodiFrame(props) {
  const ctx = useContext(GlobalContext);

  const { id, name, type, handleClose } = props;

  const customerId = parseInt(ctx.customerOverride || ctx.userProfile["https://hasura.io/jwt/claims"]['x-hasura-customer-id']);

  // Mutation to insert a payment method
  const insertMethod = async (variables) => {
    ctx.apolloClient.mutate({
      mutation: INSERT_PAYMENT_METHOD,
      variables: {
        customerId: customerId,
        name: name,
        type: type,
        gatewayToken: variables.gatewayToken,
        accountType: variables.accountType,
        accountNumber: variables.accountNumber,
        cardType: variables.cardType,
        expiration: variables.expiration,
        routingNumber: variables.routingNumber,
        source: variables.source,
      },
    onError: (err) => {Sentry.captureException(err); ctx.handleNotifications(true, "error", "Failed to insert payment method: " + err.message)}
    }).then(res => {
      if (res.data.insert_arpaymentmethods) {
        let response = res.data.insert_arpaymentmethods.returning[0];
        log && console.log(`>> INSERTED Payment Method #${response.id} for Customer #${response.customer_id}.`);
        handleClose();
      }
    }).catch(err => {
      console.log(`Error inserting customer payment method:`, err);
    });
  }

  // Mutation to update a payment method
  const updateMethod = async (variables) => {
    ctx.apolloClient.mutate({
      mutation: UPDATE_PAYMENT_METHOD,
      variables: {
        id: id,
        name: name,
        type: type,
        gatewayToken: variables.gatewayToken,
        accountType: variables.accountType,
        accountNumber: variables.accountNumber,
        cardType: variables.cardType,
        expiration: variables.expiration,
        routingNumber: variables.routingNumber,
        source: variables.source,
      },
      onError: err => ctx.handleNotifications(true, "error", "Failed to edit payment method: " + err.toString())
    }).then(res => {
      if (res.data.update_arpaymentmethods) {
        let response = res.data.update_arpaymentmethods.returning[0];
        log && console.log(`>> UPDATED Payment Method #${response.id} for Customer #${response.customer_id}.`);
        handleClose();
      }
    }).catch(err => {
      console.log(`Error updating customer payment method:`, err);
    });
  }

  // Set the src attribute depending on the type of payment
  const src = `https://portals.icheckgateway.com/iFrame/iFrame${type === `card` ? `CC` : `BA`}.aspx?appId=${process.env.REACT_APP_ICHECK_APP_ID}&appSecret=${process.env.REACT_APP_ICHECK_APP_SECRET}&firstName=${customerId}&custId=${customerId}`;

  // HTML attributes to create the iframe with (camelCase)
  const attributes = {
    id: "iMethod",
    src: src,
    width: "100%",
    height: "540px",
  };

  // The postMessage data we want to send to our iframe. This is sent after the iFrame has loaded
  const postMessageData = "Hi";

  const onReceiveMessage = (message) => {
    // iFrame sent a message and our app has received it
    if (message.data) {
      const res = message.data;
      log && console.log(`Response from iCheck:`, message.data);
      let variables = {};

      // If it's a credit card
      if (res.cardType) {
        variables.gatewayToken = res.token;
        variables.cardType = res.cardType.toLowerCase();
        variables.accountNumber = parseInt(res.cardNumber);
        variables.expiration = parseInt(res.cardExpDate);
        variables.source = res.source;
        variables.accountType = null;
        variables.routingNumber = null;
      }
      // If it's a check
      else if (res.routingNumber) {
        variables.gatewayToken = res.token;
        variables.accountType = res.accountType.toLowerCase();
        variables.accountNumber = parseInt(res.accountNumber);
        variables.routingNumber = parseInt(res.routingNumber);
        variables.source = res.source;
        variables.cardType = null;
        variables.expiration = null;
      }

      // If there's an error
      if (res.error && res.error !== null && res.error !== undefined && !variables.gatewayToken) {
        console.log(`Error from iCheck:`, res.error);
        ctx.handleNotifications(true, `error`, `Failed to create payment method: ${res.error}`);
      }
      // Else if there is no error and no id is found and the variables object is not empty, add a new entry to the database
      else if ((!id || id === null || id === undefined) && Object.getOwnPropertyNames(variables).length > 0) {
        insertMethod(variables);
      }
      // Else if there is no error and an ID and the variables object is not empty, replace the entry matching the ID
      else if (id && Object.getOwnPropertyNames(variables).length > 0) {
        updateMethod(variables);
      }
      // Otherwise, we are waiting on the payment method object to insert or update
      else {
        log && console.log(`Waiting on payment method object...`);
      }
    }
    else {
      log && console.log(`Error: No iframe data found!`);
    }
  };

  const onReady = () => {
    // iFrame has loaded
  };

  return (
    <IframeComm
      attributes={attributes}
      postMessageData={postMessageData}
      handleReady={onReady}
      handleReceiveMessage={onReceiveMessage}
    />
  )
}

////////// GRAPHQL //////////
const INSERT_PAYMENT_METHOD = gql`
mutation insert_payment_method(
  $customerId: bigint!
  $name: String!
  $type: String!
  $gatewayToken: String!
  $accountType: String
  $accountNumber: bigint!
  $cardType: String
  $expiration: smallint
  $routingNumber: bigint
  $source: String
) {
  insert_arpaymentmethods(
    objects: {
      active: true, 
      customer_id: $customerId, 
      name: $name, 
      type: $type, 
      gateway_token: $gatewayToken, 
      account_type: $accountType, 
      account_number: $accountNumber, 
      card_type: $cardType, 
      expiration: $expiration, 
      routing_number: $routingNumber, 
      source: $source, 
      primary: false
    }
  ) {
    returning {
      id
      customer_id
    }
  }
}
`;

const UPDATE_PAYMENT_METHOD = gql`
mutation update_payment_method(
  $id: bigint!
  $name: String!
  $type: String!
  $gatewayToken: String!
  $accountType: String
  $accountNumber: bigint!
  $cardType: String
  $expiration: smallint
  $routingNumber: bigint
  $source: String
) {
  update_arpaymentmethods(
    where: {id: {_eq: $id}}, 
    _set: {
      name: $name, 
      type: $type, 
      gateway_token: $gatewayToken, 
      account_type: $accountType, 
      account_number: $accountNumber, 
      card_type: $cardType, 
      expiration: $expiration, 
      routing_number: $routingNumber, 
      source: $source
    }
  ) {
    returning {
      id
      customer_id
    }
  }
}
`;