import gql from 'graphql-tag';

// ------------------------------------------------- Move Object -------------------------------------------------
const moveFragment = () => gql`
  fragment Move on moves {
    id
    active
    auto_assign
    cancel_status
    class
    createdat
    customer_id
    customer {
      ...Customer
    }
    delivery_arrived
    deliver_by
    delivery_started
    delivery_stop_id
    delivery_successful
    delivery_time
    driver_name
    driver_id
    lane_id
    lane {
      ...Lane
    }
    lyft_flag
    lyft_trigger_id
    movesByLyftTriggerId {
      ...NestedMove
    }
    parent_move {
      id
    }
    manual_flag
    move_details
    move_failed
    move_type
    pickup_arrived
    pickup_started
    pickup_stop_id
    pickup_successful
    pickup_time
    plan_id
    rate_class_override
    ready_by
    return_ride_id
    ride_type
    sequence
    status
    tookan_relationship_id
    tracking_link
    vehicle_color
    vehicle_image
    vehicle_make
    vehicle_model
    vehicle_odometer
    vehicle_stock
    vehicle_vin
    vehicle_year
    tags
    sequence
    synced_with_tookan
    priority
    pinnable
    actual_pickup_mileage
    actual_delivery_mileage
    chargeable
    updatedat
    reference_num
    dealer_contact
    consumer_pickup
    consumer_name
    consumer_phone
    consumer_type
    consumer_at_pickup
  }
  ${customerFragment()}
  ${laneFragment()}
  ${nestedMoveFragment()}
`;
// Took the following out of the move fragment due to customer permissions:
/*
    plan {
      plan_date
      driver_id
      driver_name
    }
    lyftride {
      ...Lyftride
    }

  ${lyftridesFragment()} -- at bottom
*/
// ------------------------------------------------- Favorite Location Object -------------------------------------------------
const favoriteLocationFragment = () => gql`
  fragment FavoriteLocation on favoritelocations {
    customer_id
    location_id
    location {
      ...Location
    }
  }
  ${locationFragment()}
`;
// ------------------------------------------------- Favorite Lane Object -------------------------------------------------
const favoriteLaneFragment = () => gql`
  fragment FavoriteLane on favoritelanes {
    lane_id
    customer_id
    lane {
      ...Lane
    }
  }
  ${laneFragment()}
`;
// ------------------------------------------------- Location Object -------------------------------------------------
const locationFragment = () => gql`
  fragment Location on locations {
    active
    address
    createdat
    customer_id
    customer {
      ...Customer
    }
    email
    favorite
    id
    latitude
    longitude
    name
    nickname
    phone
    place_id
    region_id
    tookan_id
    type
    updatedat
    utc_time_offset
  }
  ${customerFragment()}
`;
// ------------------------------------------------- Lane Objects -------------------------------------------------
const laneFragment = () => gql`
  fragment Lane on lanes {
    id
    active
    description
    createdat
    customer_id
    favorite
    customer {
      ...Customer
    }
    pickup {
      id
      name
      nickname
      address
      createdat
      customer_id
      email
      favorite
      latitude
      longitude
      phone
      place_id
      region_id
      tookan_id
      updatedat
      type
    }
    delivery {
      id
      name
      nickname
      address
      createdat
      customer_id
      email
      favorite
      latitude
      longitude
      phone
      place_id
      region_id
      tookan_id
      updatedat
      type
    }
    average_drive_speed_min_per_mile
    average_drive_speed_mph
    dealer_base_discount
    dealer_base_price
    dealer_base_rate
    dealer_base_rate_type
    dealer_stranded_discount
    dealer_stranded_price
    dealer_stranded_rate
    dealer_stranded_rate_type
    delivery_inspection_sec
    destination_location_id
    distance_miles
    driver_base_pay
    driver_base_pay_discount
    driver_drive_pay
    driver_pay_per_kilometer
    driver_pay_per_mile
    driver_pay_per_minute
    driver_rake
    driver_return_pay
    driver_return_pay_discount
    driver_time_pay
    duration_sec
    estimated_rideshare_return_cost
    insurance_cost
    insurance_cost_per_mile
    origin_location_id
    pickup_inspection_sec
    return_ride_wait_sec
    tolls
    updatedat
  }
  ${customerFragment()}
`;
// -------------------------------------------- Lane w/o nested fields --------------------------------------------
const simpleLaneFragment = () => gql`
  fragment SimpleLane on lanes {
    id
    active
    description
    createdat
    customer_id
    average_drive_speed_min_per_mile
    average_drive_speed_mph
    dealer_base_discount
    dealer_base_price
    dealer_base_rate
    dealer_base_rate_type
    dealer_stranded_discount
    dealer_stranded_price
    dealer_stranded_rate
    dealer_stranded_rate_type
    delivery_inspection_sec
    destination_location_id
    distance_miles
    driver_base_pay
    driver_base_pay_discount
    driver_drive_pay
    driver_pay_per_kilometer
    driver_pay_per_mile
    driver_pay_per_minute
    driver_rake
    driver_return_pay
    driver_return_pay_discount
    driver_time_pay
    duration_sec
    estimated_rideshare_return_cost
    insurance_cost
    insurance_cost_per_mile
    origin_location_id
    pickup_inspection_sec
    return_ride_wait_sec
    tolls
    updatedat
  }
`;
// ------------------------------------------------- Customer Object -------------------------------------------------
const customerFragment = () => gql`
  fragment Customer on customers {
    active
    address
    email
    id
    name
    phone
    createdat
    updatedat
    pickup_template_name
    delivery_template_name
  }
`;
// ------------------------------------------------- Rate Rules -------------------------------------------------
const raterulesFragment = () => gql`
  fragment Raterules on raterules {
    active
    class
    createdat
    customer_id
    distance_end
    distance_start
    id
    pay_rate_group_id
    rate
    rate_rule_group_id
    type
    updatedat
  }
`;
// ------------------------------------------ Plan Object With Active Moves ------------------------------------------
const planFragment = () => gql`
  fragment Plan on plans {
    id
    driver_id
    driver_name
    createdat
    updatedat
    plan_date
    moves(where: { active: { _eq: 1 } }) {
      ...Move
    }
    moves_aggregate(where: { active: { _eq: 1 } }) {
      aggregate {
        count
      }
    }
  }
  ${moveFragment()}
`;
// ------------------------------------------ Lyftrides Object With Attempts ------------------------------------------
const lyftridesFragment = () => gql`
  fragment Lyftride on lyftrides {
    move_id
    passenger_phone
    activeAttempt {
      ...Lyftrideattempts
    }
    attempts: attempts(order_by: { createdat: desc_nulls_last }) {
      ...Lyftrideattempts
    }
    createdat
    updatedat
  }
  ${lyftrideattemptsFragment()}
`;
// ------------------------------------ Lyftrideattempts Object with current and all ----------------------------------
const lyftrideattemptsFragment = () => gql`
  fragment Lyftrideattempts on lyftrideattempts {
    id
    move_id
    status
    requested_at
    driver_phone
    driver_vehicle_color
    driver_vehicle_license_plate
    driver_vehicle_license_plate_state
    driver_vehicle_make
    driver_vehicle_model
    driver_vehicle_year
    driver_first_name
    destination_location_id
    driver_id
    driver_rating
    estimated_cost_max
    estimated_cost_min
    estimated_ride_cost
    origin_location_id
    passenger_phone
    requester
    ride_cost
    ride_distance
    ride_duration
    ride_id
    stop_id
    surge_cost_percentage
    createdat
    updatedat
  }
`;
// ------------------------------------ Lyftrideattempts Object with current and all ----------------------------------
const nestedMoveFragment = () => gql`
  fragment NestedMove on moves {
    id
    driver_id
    driver_name
    parent_move {
      id
    }
  }
`;

const fragments = {
  move: moveFragment(),
  favoriteLocation: favoriteLocationFragment(),
  favoriteLane: favoriteLaneFragment(),
  location: locationFragment(),
  lane: laneFragment(),
  simpleLane: simpleLaneFragment(),
  customer: customerFragment(),
  raterules: raterulesFragment(),
  plan: planFragment(),
  lyftrides: lyftridesFragment(),
  lyftrideattempts: lyftrideattemptsFragment(),
  nestedMove: nestedMoveFragment(),
};

export default fragments;
