import React, { useState, useContext } from 'react';
import axios from 'axios';
import { GlobalContext } from '../../global-context';

import { makeStyles, Container, Grid, Typography, Icon, IconButton } from '@material-ui/core';
import Divide from '../reusable/Divide';

import gql from "graphql-tag";
import { Subscription } from "react-apollo";
import * as Sentry from "@sentry/react";

import Loading from '../utils/Loading';
import ConciergeStepper from './conciergeDetails/ConciergeStepper';
import ConciergeDetailsInfo from './conciergeDetails/ConciergeDetailsInfo';
import ConciergeDetailsLocation from './conciergeDetails/ConciergeDetailsLocation';
import ConciergeDetailsTracking from './conciergeDetails/ConciergeDetailsTracking';

const log = false;

////////// COMPONENT //////////
export default function ConciergeDetails(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const moveId = props.match.params.id;

  const [tookan, setTookan] = useState({ loading: true, pickup: { images: [] }, delivery: { images: [] }, driver: null });

  const GET_TOOKAN_DATA = async (api_key, job_id) => axios.post(`${process.env.REACT_APP_BASEURL_TOOKAN}/get_task_details`, { api_key: api_key, job_id: job_id });
  const GET_TOOKAN_DRIVERS = async (api_key) => axios.post(`${process.env.REACT_APP_BASEURL_TOOKAN}/get_available_agents`, { api_key: api_key });

  const getTookanData = async (move = null, loaner = null) => {
    // log && console.log(`Getting data from tookan...`);

    let loanerPickupJobId = loaner && loaner.pickup_stop_id ? loaner.pickup_stop_id : null;
    let loanerDeliveryJobId = loaner && loaner.delivery_stop_id ? loaner.delivery_stop_id : null;
    let loanerPickupImages = [];
    let loanerDeliveryImages = [];
    let loanerPickupImage = null;
    let loanerDeliveryImage = null;

    let consumerPickupJobId = move && move.pickup_stop_id ? move.pickup_stop_id : null;
    let consumerDeliveryJobId = move && move.delivery_stop_id ? move.delivery_stop_id : null;
    let consumerPickupImages = [];
    let consumerDeliveryImages = [];
    let consumerPickupImage = null;
    let consumerDeliveryImage = null;

    let driver = null;

    // Tookan LOANER PICKUP images
    if (loanerPickupJobId) {
      await GET_TOOKAN_DATA(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`], loanerPickupJobId)
        .then(res => {
          // log && console.log(`TOOKAN Loaner Pickup Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(item => item.description);
            tookanData = tookanData.filter(item => {
              if (item.includes(`task_images`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              let driverFront = tookanData.filter(obj => obj.includes(`Driver_Front`));
              if (driverFront && driverFront.length > 0) loanerPickupImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedData = tookanData.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedData && extractedData.length > 0) {
                for (let i = 0; i < extractedData.length; i++) {
                  if (extractedData[i].includes(`task_images`)) loanerPickupImages.push(extractedData[i]);
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(`Failed to retrieve Tookan loaner pickup data:`, err);
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan LOANER DELIVERY images
    if (loanerDeliveryJobId) {
      await GET_TOOKAN_DATA(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`], loanerDeliveryJobId)
        .then(res => {
          // log && console.log(`TOOKAN Loaner Delivery Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(item => item.description);
            tookanData = tookanData.filter(item => {
              if (item.includes(`task_images`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              let driverFront = tookanData.filter(obj => obj.includes(`Driver_Front`));
              if (driverFront && driverFront.length > 0) loanerDeliveryImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedData = tookanData.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedData && extractedData.length > 0) {
                for (let i = 0; i < extractedData.length; i++) {
                  if (extractedData[i].includes(`task_images`)) loanerDeliveryImages.push(extractedData[i]);
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(`Error retrieving Tookan loaner delivery data:`, err);
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan CONSUMER PICKUP images
    if (consumerPickupJobId) {
      await GET_TOOKAN_DATA(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`], consumerPickupJobId)
        .then(res => {
          // log && console.log(`TOOKAN Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(item => item.description);
            tookanData = tookanData.filter(item => {
              if (item.includes(`task_images`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              let driverFront = tookanData.filter(obj => obj.includes(`Driver_Front`));
              if (driverFront && driverFront.length > 0) consumerPickupImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedData = tookanData.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedData && extractedData.length > 0) {
                for (let i = 0; i < extractedData.length; i++) {
                  if (extractedData[i].includes(`task_images`)) consumerPickupImages.push(extractedData[i]);
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(`Failed to retrieve Tookan consumer pickup data:`, err);
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan CONSUMER DELIVERY images
    if (consumerDeliveryJobId) {
      await GET_TOOKAN_DATA(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`], consumerDeliveryJobId)
        .then(res => {
          // log && console.log(`TOOKAN Delivery Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(item => item.description);
            tookanData = tookanData.filter(item => {
              if (item.includes(`task_images`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              let driverFront = tookanData.filter(obj => obj.includes(`Driver_Front`));
              if (driverFront && driverFront.length > 0) consumerDeliveryImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedData = tookanData.map(obj => {
                if (obj.includes(`fleet_data`)) return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedData && extractedData.length > 0) {
                for (let i = 0; i < extractedData.length; i++) {
                  if (extractedData[i].includes(`task_images`)) consumerDeliveryImages.push(extractedData[i]);
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(`Error retrieving Tookan consumer delivery data:`, err);
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan DRIVER data
    if (move && move.driver_id) {
      await GET_TOOKAN_DRIVERS(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`])
        .then(res => {
          // log && console.log(`TOOKAN Driver Response:`, res);
          if (res.status === 200 && res.data.data.length > 0) {
            let tookanDrivers = res.data.data.filter(driver => driver.fleet_id === move.driver_id);
            if (tookanDrivers.length > 0) driver = tookanDrivers[0];
          }
        })
        .catch(err => {
          console.log(`Failed to retrieve Tookan drivers:`, err);
          setTookan({ ...tookan, loading: false });
        });
    }

    // Set Tookan state object
    setTookan({
      loading: false,
      loanerPickupImages: loanerPickupImages,
      loanerDeliveryImages: loanerDeliveryImages,
      loanerPickupImage: loanerPickupImage,
      loanerDeliveryImage: loanerDeliveryImage,
      consumerPickupImages: consumerPickupImages,
      consumerDeliveryImages: consumerDeliveryImages,
      consumerPickupImage: consumerPickupImage,
      consumerDeliveryImage: consumerDeliveryImage,
      driver: driver
    });
  }

  const fetchTookan = (move, loaner) => {
    getTookanData(move, loaner);
    if (move.status && move.cancel_status === null && move.status !== `delivery successful` && (move.status.includes(`pickup`) || move.status.includes(`delivery`))) {
      setInterval(() => getTookanData(move, loaner), 60000);
    }
  };

  const goBack = () => {
    props.history.goBack();
  }

  return (<>
    <div className={cls.root}>
      <Container maxWidth="lg">
        <IconButton style={{ marginRight: ctx.theme.spacing(1) }} className={cls.iconBtn} onClick={() => goBack()}>
          <Icon>arrow_back</Icon>
        </IconButton>
        <Typography className={cls.headL}>Track Your Vehicle</Typography>

        <div style={{ float: 'right' }}>
          <Typography className={cls.headR}>*If you have any issues, please call <a className={cls.phone} href={`tel:18009137674`}>(800) 913-7674</a></Typography>
        </div>

        <div style={{ width: '100%', height: '24px' }} />
        { ctx && ctx.userIsAuthenticated() && (
          <Subscription
            subscription={GET_CONCIERGE_MOVE}
            variables={{ moveId: moveId, customerId: parseInt(ctx.customerOverride || ctx.userProfile["https://hasura.io/jwt/claims"]['x-hasura-customer-id']) }}
            onError={(error) => {console.error(error); Sentry.captureException(error)}}>
            {({ loading, data }) => {
              if (loading) return <Loading fixed />
              if (data && data.moves && data.moves.length > 0) {
                const move = data.moves[0];
                log && console.log(`Concierge Details:`, move);

                const loaner = move.moveByReturnRideId && move.moveByReturnRideId.tags && move.moveByReturnRideId.tags.includes(`loaner`) ? move.moveByReturnRideId : null;

                // Get images and driver data from Tookan (Set interval to fetch)
                if (tookan.loading) {
                  fetchTookan(move, loaner);
                }

                return (<>
                  {(!move.cancel_status && move.status) || (loaner && !loaner.cancel_status && loaner.status) ? <ConciergeStepper move={move} loaner={loaner} /> : null}
                  <ConciergeDetailsInfo move={move} loaner={loaner} tookan={tookan} />

                  <Divide spacer tip="See information on where your vehicle is being picked up from as well as where it is being delivered">Location Details</Divide>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <ConciergeDetailsLocation title="Your Location" type="pickup" move={move} pickupImages={tookan.consumerPickupImages} deliveryImages={tookan.consumerDeliveryImages} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <ConciergeDetailsLocation title="Service Location" type="delivery" move={move} pickupImages={tookan.loanerPickupImages} deliveryImages={tookan.loanerDeliveryImages} />
                    </Grid>
                  </Grid>

                  {!move.cancel_status && move.status && !move.status.includes(`delivery successful`) && (move.status.includes(`pickup`) || move.status.includes(`delivery`)) && move.tracking_link ? <>
                    <Divide spacer tip="Track where the driver is currently located">Tracking Map</Divide>
                    <ConciergeDetailsTracking move={move} />
                  </> : null}
                </>)
              }
              else return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>NO CONCIERGE RECORD FOUND</Typography>
                </div>
              )
            }}
          </Subscription>)
        }
      </Container>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  iconBtn: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginTop: '-12px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-14px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-16px',
    },
  },
  headL: {
    verticalAlign: 'top',
    display: 'inline-block',
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  headR: {
    verticalAlign: 'top',
    display: 'inline-block',
    lineHeight: 1,
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  phone: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: 'underline',
  },
  notFound: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

////////// GRAPHQL //////////
const GET_CONCIERGE_MOVE = gql`
subscription get_concierge_move($moveId: bigint!, $customerId: bigint!) {
  moves(where: {id: {_eq: $moveId}, customer_id: {_eq: $customerId}, consumer_pickup: {_eq: true}}) {
    id
    consumer_pickup
    consumer_name
    consumer_phone
    status
    cancel_status
    driver_id
    driver_name
    customer {
      id
      name
    }
    lane {
      id
      distance_miles
      pickup {
        id
        name
        address
      }
      delivery {
        id
        name
        address
      }
    }
    pickup_stop_id
    delivery_stop_id
    pickup_time
    delivery_time
    pickup_arrived
    delivery_arrived
    tracking_link
    vehicle_make
    vehicle_model
    vehicle_year
    vehicle_color
    moveByReturnRideId {
      id
      status
      cancel_status
      tags
      pickup_stop_id
      delivery_stop_id
      pickup_time
      delivery_time
      pickup_arrived
      delivery_arrived
      tracking_link
      vehicle_make
      vehicle_model
      vehicle_year
      vehicle_color
    }
  }
}
`;