import React from "react";
import ReactDOM from "react-dom";
import "typeface-roboto";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";

process.env.REACT_APP_ENV !== "development" &&
  Sentry.init({
    dsn: "https://bd3b746b090e499886bcc23149873f9f@o491290.ingest.sentry.io/5556740",
    autoSessionTracking: true,
    environment: process.env.REACT_APP_ENV,
    release: "dealer-portal@v2.8.0",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });

export const history = createBrowserHistory();

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH_DOMAIN}
    clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
    redirectUri={window.location.origin}
    audience={`https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`}
    scope="openid email profile"
    onRedirectCallback={onRedirectCallback}
  >
    <Router history={history}>
      <App />
    </Router>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
