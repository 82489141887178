import React, { useContext } from 'react';
import { GlobalContext } from '../../../global-context';
import gql from 'graphql-tag';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/Modal';
import * as Sentry from "@sentry/react";

const log = false;

////////// COMPONENT //////////
export default function MoveCancelModal(props) {
  const ctx = useContext(GlobalContext);

  const { open, onClose, moveInput } = props;

  const handleSubmit = async () => {
    ctx.apolloClient.mutate({
      mutation: REQUEST_MOVE_CANCEL,
      variables: { id: moveInput.id },
      onError: err => {Sentry.captureException(err); ctx.handleNotifications(true, `error`, `Failed to request move cancellation: ` + err.toString())}
    }).then(res => {
      if (res.data.update_moves) {
        const moveRes = res.data.update_moves.returning[0];
        console.log(moveRes);
        log && console.log(`>> UPDATED move #${moveRes.id} with a cancel_status of '${moveRes.cancel_status}'.`);
        ctx.handleNotifications(true, `success`, `Cancel request successful for Move #${moveRes.id}.`);
        handleClose();
      }
    }).catch(err => {
      console.log(`Error updating move:`, err);
    });
  }

  const handleClose = () => {
    if (onClose) onClose();
  }

  return (<>
    <Modal open={open}>

      <ModalHeader handleClose={handleClose}>Cancellation Request for Move #{moveInput && moveInput.id}</ModalHeader>

      <ModalContent subtitle={`Are you sure you want to cancel this move? Your cancellation will be pending until reviewed by a HopDrive dispatcher.`} />

      <ModalFooter>
        <ModalAction onClick={() => handleSubmit()}>Confirm</ModalAction>
        <ModalAction onClick={() => handleClose()} color="secondary">Cancel</ModalAction>
      </ModalFooter>

    </Modal>
  </>)
}

////////// GRAPHQL //////////
const REQUEST_MOVE_CANCEL = gql`
mutation request_move_cancel($id: bigint!) {
  update_moves(where: {id: {_eq: $id}}, _set: {cancel_status: "pending"}) {
    affected_rows
    returning {
      id
      cancel_status
      status
    }
  }
}
`;