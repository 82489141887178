import React, { useEffect, useState } from 'react'

export default function PhoneNumber({phone}) {
    const [formattedPhone, setFormattedPhone] = useState('')

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
    }

    useEffect(() => {
        setFormattedPhone(formatPhoneNumber(phone))
    }, [])

    return (
        <span>
            {formattedPhone}
        </span>
    )
}