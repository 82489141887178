import React from 'react';
import { withRouter } from 'react-router';
import { makeStyles, Box, Chip, Grid, Typography, Tooltip } from '@material-ui/core';
import MoveStatusTracker from '../../reusable/MoveStatusTracker';
import PhoneNumber from '../../reusable/PhoneNumber';
import moment from 'moment'

const capFirst = (str) => { if (str) return str.charAt(0).toUpperCase() + str.slice(1); }

////////// COMPONENT //////////
function DashboardTile(props) {
  const cls = useStyles();

  const { move } = props;

  const goToMoveDetails = () => {
    props.history.push(`/moves/${move.id}`);
  }

  const getVehicle = () => {
    let vehicle = ``;
    if (!move.vehicle_make && !move.vehicle_model) vehicle = `No Vehicle Info`;
    else vehicle = `${move.vehicle_year} ${move.vehicle_make} ${move.vehicle_model}`;

    let vehicleColor = ``;
    if (move.vehicle_color) vehicleColor = ` ${capFirst(move.vehicle_color)}`;

    return vehicle + vehicleColor;
  }

  return (<>
    <div className={cls.move} onClick={goToMoveDetails}>
      <Grid container spacing={2} alignItems="flex-start" wrap="nowrap" className={cls.top}>
        <Grid item className={cls.preventOverflow}>
          <Typography className={cls.txtVehicle + " " + cls.ellipse}>{move.consumer_name} <PhoneNumber phone={move.consumer_phone} /></Typography>
          <Typography className={cls.txtLane}>{move.lane.description}</Typography>
        </Grid>

        <Grid item xs>
          <Typography className={cls.txtId}><Tooltip title={move.id}><Chip size="small" label="Loaner" variant="outlined" color="secondary" component="span"></Chip></Tooltip></Typography>
        </Grid>
      </Grid>

      <MoveStatusTracker move={move} size="small" />

      <Grid container spacing={2} alignItems="flex-start" wrap="nowrap" className={cls.bottom}>
        <Grid item xs={3}>
          {/* <Typography className={cls.txtKey}>Stock</Typography> */}
          <Typography className={cls.txtVal} style={{ textAlign: 'left' }}>{moment(move.pickup_time).format('M/D/YY h:mma')}</Typography>
        </Grid>

        <Grid item xs={6}>
          {/* <Typography className={cls.txtKey}>VIN</Typography> */}
          <Typography className={cls.txtVal} style={{ textAlign: 'center' }}>{move.vehicle_vin ? move.vehicle_vin.slice(-6) : `N/A`} <em>({getVehicle()})</em></Typography>
        </Grid>

        <Grid item xs={3}>
          {/* <Typography className={cls.txtKey}>Ref</Typography> */}
          <Typography className={cls.txtVal} style={{ textAlign: 'right' }}>{move.reference_num ? move.reference_num : move.vehicle_stock}</Typography>
        </Grid>
      </Grid>

      {move.cancel_status ? <>
        <div className={cls.cancelled}>
          <Typography className={cls.txtCancelled}>CANCELLED</Typography>
        </div>
      </> : null}
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  move: {
    padding: theme.spacing(1.5),
    position: 'relative',
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#eee',
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  top: {
    minWidth: '100%',
    marginBottom: theme.spacing(1),
  },
  bottom: {
    minWidth: '100%',
    marginTop: theme.spacing(1),
  },
  txtVehicle: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  txtLane: {
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  txtId: {
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  txtKey: {
    textAlign: 'center',
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  txtVal: {
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  cancelled: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(1),
    backgroundColor: '#000000a0',
  },
  txtCancelled: {
    position: 'absolute',
    top: '40%',
    left: 0,
    right: 0,
    color: '#fff',
    lineHeight: 1,
    textAlign: 'center',
    fontSize: '28px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '21px',
    },
  },
  ellipse: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  preventOverflow: {
    maxWidth: '85%',
  },
}));

////////// EXPORT //////////
export default withRouter(DashboardTile);