import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { makeStyles, Grid, Typography } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from '@material-ui/pickers';

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  date: {
    width: '100%',
  },
  time: {
    width: '100%',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  tip: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
}));

////////// COMPONENT //////////
export default function DatetimeSelect(props) {
  const cls = useStyles();

  const { timeData, onChange, tip, useUtc, disabled, getDefaultStartTime } = props;

  const [time, setTime] = useState(timeData ? timeData : null);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (timeData) setTime(timeData);
    // console.log(`DatetimeSelect received new default time prop: `, timeData)
  }, [timeData]);

  const handleTimeChange = () => event => {
    const newTime = (useUtc) ? moment.utc(moment(event)) : moment(event)
    //Assure selected time is not in the past
    if (newTime > Date.now()) {
      if (newTime.diff(moment(Date.now()), 'minutes') < 90) {
        // Pickup time is within 90 minutes of current time
        let defaultTime = getDefaultStartTime()
        setTime(newTime.format())
        setErrorText('Pickup time must be at least 90 minutes from the current time')
        setError(true)
        if (onChange) onChange(defaultTime)
      } else {
        setTime(newTime.format())
        setErrorText('')
        setError(false)
        if (onChange) onChange(newTime)
      }
    } else {
      let defaultTime = getDefaultStartTime()
      setTime(newTime.format())
      setErrorText('Cannot Set Pickup Time in Past')
      setError(true)
      if (onChange) onChange(defaultTime)
    }
  }

  return (<>
    {tip ? <>
      <Typography className={cls.tip}>{tip}</Typography>
      <div className={cls.break} />
    </> : null}

    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={2}>

        <Grid item xs={6}>
          <DatePicker
            error={props.validation && props.validation.pdate ? !props.validation.pdate : false}
            helperText={props.validation && !props.validation.ptime ? "Pickup date cannot be in the past" : null}
            disabled={disabled}
            disablePast
            autoOk
            orientation="portrait"
            format="MM/dd/yyyy"
            label="Pickup Date"
            margin="none"
            inputVariant="outlined"
            value={time}
            onChange={handleTimeChange()}
            className={cls.date}
          />
        </Grid>

        <Grid item xs={6}>
          <TimePicker
            // error={props.validation && props.validation.ptime ? !props.validation.ptime.toString() : "false"} // dupe
            disabled={disabled}
            autoOk
            orientation="portrait"
            minutesStep={5}
            label="Pickup Time"
            margin="none"
            inputVariant="outlined"
            value={time}
            onChange={handleTimeChange()}
            error={error ? true: (props.validation && props.validation.ptime ? !props.validation.ptime : false)}
            helperText={errorText ? errorText : (props.validation && !props.validation.ptime ? "Pickup time cannot be in the past" : null)}
            className={cls.time}
          />
        </Grid>

      </Grid>
    </MuiPickersUtilsProvider>
  </>)
}