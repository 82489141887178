import React, { useContext } from 'react';
import { GlobalContext } from '../../../global-context';
import gql from 'graphql-tag';
import * as Sentry from "@sentry/react";
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/Modal';

const log = false;

////////// COMPONENT //////////
export default function MethodRemoveModal(props) {
  const ctx = useContext(GlobalContext);

  const { open, onClose, methodInput } = props;

  const handleSubmit = async () => {
    ctx.apolloClient.mutate({
      mutation: REMOVE_PAYMENT_METHOD,
      variables: { id: methodInput.id },
      onError: err => {Sentry.captureException(err); ctx.handleNotifications(true, `error`, `Failed to update payment method: ` + err.toString())}
    }).then(res => {
      if (log && res.data.update_arpaymentmethods) {
        const response = res.data.update_arpaymentmethods;
        log && console.log(`>> Updated ${response.affected_rows} Payment Method #${response.returning[0].id} for Customer #${response.returning[0].customer_id}.`);
      }
    }).catch(err => {
      console.log(`Error updating payment method:`, err);
    });

    handleClose();
  }

  const handleClose = () => {
    if (onClose) onClose();
  }

  return (<>
    <Modal open={open}>
      
      <ModalHeader handleClose={handleClose}>Remove Payment Method</ModalHeader>

      <ModalContent subtitle={`Are you sure you want to remove this payment method? (${methodInput && methodInput.name})`} />

      <ModalFooter>
        <ModalAction onClick={() => handleSubmit()}>Confirm</ModalAction>
        <ModalAction onClick={() => handleClose()} color="secondary">Cancel</ModalAction>
      </ModalFooter>

    </Modal>
  </>)
}

////////// GRAPHQL //////////
const REMOVE_PAYMENT_METHOD = gql`
mutation remove_payment_method($id: bigint!) {
  update_arpaymentmethods(
    where: { id: { _eq: $id } },
    _set: { active: false }) {
    affected_rows
    returning {
      id
      customer_id
    }
  }
}
`;