import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../../global-context';

import { makeStyles, Icon, Tooltip } from '@material-ui/core';

import gql from "graphql-tag";
import { Subscription } from 'react-apollo';
import * as Sentry from "@sentry/react";

import Loading from '../../utils/Loading';

import fragments from '../../utils/graphQL/fragments';
import LocationSelect from './LocationSelect';
import FavoriteSelect from './FavoriteSelect';

const log = false;

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    background: '#fff',
  },
  lane: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  spacer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: theme.spacing(2),
    minHeight: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '48px',
    minHeight: '48px',
  },
  swapIcon: {
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
    transition: '0.2s',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)',
    },
  },
  favIcon: {
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.error.main,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  favIconActive: {
    color: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.error.light,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
}));

////////// COMPONENT //////////
export default function LaneForm(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { laneData, onLaneChange, getLaneByLocationIds } = props;

  const customerId = parseInt(ctx.customerOverride || ctx.userProfile["https://hasura.io/jwt/claims"]['x-hasura-customer-id']);

  const [lane, setLane] = useState(laneData);
  const [pickup, setPickup] = useState(null);
  const [delivery, setDelivery] = useState(null);
  const [expand, setExpand] = useState(false);

  let lanes = [];

  useEffect(() => {
    log && console.log('LaneForm: Received updated default lane', laneData)
    if (laneData && laneData.pickup) setPickup(laneData.pickup)
    if (laneData && laneData.delivery) setDelivery(laneData.delivery)
  }, [laneData]);

  useEffect(() => {
    handleLane();
  }, [pickup, delivery]);

  const handleLane = async () => {
    if (pickup && delivery) {
      if (pickup.id === delivery.id) {
        log && console.log('Duplicate locations')
        if (onLaneChange) onLaneChange({ pickup: null, delivery: null });
        setLane(null);
      } else {
        const foundLane = await getLaneByLocationIds(pickup.id, delivery.id);
        log && console.log(`Setting new lane... (Full Lane):`, foundLane);
        if (!foundLane) {
          if (onLaneChange) onLaneChange({ pickup: pickup, delivery: delivery });
        } else {
          if (onLaneChange) onLaneChange(foundLane);
          setLane(foundLane);
        }
      }
    } else if (pickup) {
      if (onLaneChange) onLaneChange({ pickup: pickup, delivery: delivery });
    } else if (delivery) {
      if (onLaneChange) onLaneChange({ pickup: pickup, delivery: delivery });
    } else {
      if (onLaneChange) onLaneChange({ pickup: null, delivery: null });
      setLane(null);
    }
  }

  const handlePickup = pickup => {
    setPickup(pickup);
  }

  const handleDelivery = delivery => {
    setDelivery(delivery);
  }

  const handleFavoriteSelect = newLane => {
    setPickup(newLane.pickup);
    setDelivery(newLane.delivery);
  }

  const handleSwapLocations = () => {
    setPickup(delivery);
    setDelivery(pickup);
    log && console.log(`Locations have been swapped!`);
  }

  const handleExpand = () => {
    setExpand(!expand);
  }

  const handleLaneFavorite = async (favoriteLanes) => {
    if (lane.id) {
      try {
        const res = await ctx.apolloClient.mutate({
          mutation: UPDATE_LANE_FAVORITE,
          variables: { id: lane.id, fav: !laneIsFavorited(lane, favoriteLanes) }
        });
        if (res.data && res.data.update_lanes) {
          onLaneChange({ ...lane, favorite: !lane.favorite });
          log && console.log(`>> UPDATED Favorite Lane to ${!lane.favorite}:`, res.data.update_lanes);
        }
      }
      catch (err) {
        log && console.log(`Failed to update lane:`, err);
        ctx.handleNotifications(true, `error`, `Failed to update lane: ` + err.toString());
      }
    }
    else log && console.log(`Error: Lane is not valid and must be created before setting favorite!`);
  }

  const laneIsFavorited = (lane, lanes) => lanes.filter(o => o.favorite).map(o => o.id).includes(lane.id)

  return (<>
    <Subscription subscription={GET_LANES} variables={{ customerId: customerId }}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />
        if (error) {
          log && console.log(`Failed to retrieve lanes:`, error);
          ctx.handleNotifications(true, `error`, `Subscription failed to retrieve lanes: ` + error.toString());
        }
        if (data && data.lanes) {
          let lanes = data.lanes.length > 0 ? data.lanes : [];
          let favoriteLanes = lanes.filter(l => l.favorite === true);

          return (<>
            <div className={cls.paper}>
              <div className={cls.lane}>

                <LocationSelect valid={props.validation && props.validation.pickup ? props.validation.pickup : false} locationData={pickup} onChange={handlePickup} label="Pickup Location" />

                {pickup || delivery ?
                  <div className={cls.actions}>
                    <div className={cls.action}>
                      <Tooltip placement="top" title="Swap Locations">
                        <Icon className={cls.swapIcon} onClick={() => handleSwapLocations()}>swap_horiz</Icon>
                      </Tooltip>
                    </div>
                    {pickup && delivery && lane && lane.id ?
                      <div className={cls.action}>
                        <Tooltip placement="top" title={laneIsFavorited(lane, favoriteLanes) ? `Unfavorite Lane` : `Favorite Lane`}>
                          <Icon className={laneIsFavorited(lane, favoriteLanes) ? cls.favIconActive : cls.favIcon} onClick={() => handleLaneFavorite(favoriteLanes)}>{laneIsFavorited(lane, favoriteLanes) ? `favorite` : `favorite_border`}</Icon>
                        </Tooltip>
                      </div> : null
                    }
                  </div> : <div className={cls.spacer} />
                }

                <LocationSelect valid={props.validation && props.validation.delivery ? props.validation.delivery : false} locationData={delivery} onChange={handleDelivery} label="Delivery Location" />

              </div>

              <FavoriteSelect expand={expand} handleExpand={handleExpand} favoriteLanes={favoriteLanes} handleFavoriteSelect={handleFavoriteSelect} />

            </div>
          </>)
        } else {
          return <div>Error Finding Lanes</div>;
        }
      }}

    </Subscription>
  </>)
}

////////// GRAPHQL //////////
const GET_LANES = gql`
subscription get_lanes($customerId: bigint!) {
  lanes(where: {customer_id: {_eq: $customerId}, active: {_eq: 1}}, order_by: [{favorite: desc}, {description: asc}]) {
    id
    description
    favorite
    pickup {
      id
      name
      nickname
      address
      email
      phone
    }
    delivery {
      id
      name
      nickname
      address
      email
      phone
    }
    delivery_inspection_sec
    duration_sec
    pickup_inspection_sec
    return_ride_wait_sec
  }
}
`;

const GET_LANES_BY_LOCATIONS = gql`
query get_lanes_by_locations($pickupId: bigint!, $deliveryId: bigint!) {
  lanes(where: {origin_location_id: {_eq: $pickupId}, destination_location_id: {_eq: $deliveryId}}, order_by: [{favorite: desc}, {description: asc}]) {
    ...Lane
  }
}
${fragments.lane}
`;

const INSERT_LANES = gql`
mutation insert_lanes($laneObjects: [lanes_insert_input!]!){
  insert_lanes(objects: $laneObjects) {
    returning {
      ...Lane
    }
  }
}
${fragments.lane}
`;

const UPDATE_LANE_FAVORITE = gql`
mutation update_lane_favorite($id: bigint!, $fav: Boolean!) {
  update_lanes(where: {id: {_eq: $id}}, _set: {favorite: $fav}) {
    affected_rows
    returning {
      id
      description
      favorite
    }
  }
}
`;